import React from 'react';
import authService from '../api-authorization/AuthorizeService';
import {
    Paper, Button, Card, CardContent,
    Radio, RadioGroup, FormControlLabel, Grid
} from '@material-ui/core';
import { Media } from 'reactstrap';
import RecursiveTreeView from '../treeView/RecursiveTreeView';


export default function MoveCopy(props) {
    const inventoryJson = props.inventoryJson;
    const cancelClick = props.onClick;
    const imageUrl = props.imageUrl;
    const roles = props.roles;
    const fullTreeFromRoot = props.fullTreeFromRoot;
    const [moveOrCopy, setMoveOrCopy] = React.useState("Copy");
    const [instructionsForUsingTree, setInstructionsForMovingTree] = React.useState(<><b>{moveOrCopy}</b> <i>"{inventoryJson.Name}"</i> to:</>);

    const handleRadioChange = (event) => {
        setMoveOrCopy(event.target.value);
        setInstructionsForMovingTree(<><b>{event.target.value}</b> <i>"{inventoryJson.Name}"</i> to:</>);
    };

    const cancelMoveCopyMode = () => {
        props.onCancel();
    };

    const getSelectedDestinationId = (id, name) => {
        if (!roles.includes("MoveCopy")) {
            alert("You do not have permission to move or copy.");
            return;
        };

        if (window.confirm("Important! " + moveOrCopy + " " + inventoryJson.Name + " to " + name.Name + "? You cannot undo this.")) {
            inventoryJson.Action = (moveOrCopy === "Move" ? 4 : 7);
            inventoryJson.ParentId = id;
            moveOrCopyNode(inventoryJson, id);

        }
        else {
            alert(moveOrCopy + " cancelled for " + inventoryJson.Name);
        };
    };

    return (
        <>
            <Card align="center" style={{ padding: "1px", flexGrow: "1", height: "100%" }}>
                <CardContent style={{ padding: "1px", flexGrow: "1", height: "100%" }}>
                    <Grid container justify="center" alignItems="flex-start" style={{ padding: "1px", flexGrow: "1", height: "100%" }}>
                        <Grid item xs={12} md={6} style={{ padding: "1px",  height: "100%" }}>
                            <Grid container justify="center" alignItems="center" >
                                <Grid item xs={12} md={12} wrap="nowrap" >
                                    <Button size="small" variant="outlined" onClick={cancelMoveCopyMode}>
                                        Cancel Move/Copy
                                    </Button><RadioGroup aria-label="status" name="status1" style={{ align: "center" }} value={moveOrCopy} row onChange={handleRadioChange}>
                                        <FormControlLabel value="Copy" control={<Radio />} label="Copy" />
                                        <FormControlLabel value="Move" control={<Radio />} label="Move" />
                                    </RadioGroup>
                                    {instructionsForUsingTree}<br /><br />
                                </Grid>
                            </Grid>
                            <RecursiveTreeView inventoryNode={fullTreeFromRoot} focusClick={getSelectedDestinationId} roles={roles} style={{ padding: "1px", flexGrow: "1", height: "100%" }}></RecursiveTreeView>
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />

                        </Grid>
                        <Grid item xs={false} sm={false} md={6} style={{ padding: "1px",  height: "100%" }}>
                            <div style={{ padding: "2px" }} >
                                &nbsp;
                                            </div>
                            <Paper style={{ padding: "2px", color: "blue" }} elevation={1}><i>- {inventoryJson.Name} -</i></Paper>
                            <Media hidden={inventoryJson.id < 1 ? true : false} object src={imageUrl} style={{ height: "210px" }} width="auto" alt="- no image -" />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />
                            <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />  <br /><br /><br /><br /><br />

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );

    async function moveOrCopyNode(nodeToUpdate, idDestination) {
        setInstructionsForMovingTree(<b>{moveOrCopy} in progress. Please wait...</b>);
        const token = await authService.getAccessToken();
        const url = "inventoryhome";
        const response = await fetch(url, {
            method: 'POST',
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Pragma': 'no-cache'
            },
            body: JSON.stringify(nodeToUpdate)
        });

        cancelClick();
        return response.json();
    };
};

