import React from 'react';
import MaterialTable from 'material-table';
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'

export default function GlobalTable(props) {
    const formatTitle = (title) => {
        return title;
    };

    const tableRowClicked = (event, rowData) => {
        props.instance.getNode(rowData.id, event);
    }

    return (
        <MaterialTable
            options={{
                pageSize: 20,
                paging: true,
                exportButton: false,
                exportAllData: false,
                padding: "dense",
                rowStyle: {
                    fontSize: 12.5,
                },
            }}
            onRowClick={tableRowClicked}
            columns={[
                { title: 'Name', field: 'Name', searchable: true},
                { title: 'ID', field: 'id', hidden: true, searchable: false },
                { title: 'Status', field: 'GearCheckStatus', searchable: true },
                { title: 'Comments', field: 'GearCheckComment', searchable: false },
                { title: 'Quantity', field: 'Quantity', export: true, searchable: false },
                { title: 'Minimum Quantity', field: 'MinimumQuantity', export: true, searchable: false },
                {
                    title: 'Type', field: 'ItemType', export: true, searchable: false,
                    render: (rowData) => {
                        if (rowData.ItemType === "1")
                            return "unit";
                        else if (rowData.ItemType === "2")
                            return "box";
                        else if (rowData.ItemType === "3")
                            return "pkg";
                    }
                },
                {
                    title: 'Expiration', field: 'ExpirationDate', export: true, searchable: false,
                    render: (rowData) => {
                        let x = new Date(rowData.ExpirationDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
                        let commaIndex = x.indexOf(",");
                        return rowData.ExpirationDate === null || rowData.ExpirationDate.startsWith("0001-") ? " " : x.substring(0, commaIndex);
                    }
                },
                {
                    title: 'Last Checked', field: 'GearCheckDateTime', export: true, searchable: false, type: "datetime",
                    dateSetting: "en-US"
                },
                { title: 'Description', field: 'Description', searchable: false },
                { title: 'Instructions', field: 'GearCheckInstructions', searchable: false },
            ]}
            icons={{
                Add: AddBox,
                Check: Check,
                Clear: Clear,
                Delete: DeleteOutline,
                DetailPanel: ChevronRight,
                Edit: Edit,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                ResetSearch: Clear,
                Search: Search,
                SortArrow: ArrowUpward,
                ThirdStateCheck: Remove,
            }}
            title={formatTitle(props.instance.state.inventory.Name)}
            data={props.instance.state.inventory.ChildNodesExt}
        />
    );
};