import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Grid from '@material-ui/core/Grid';
import ModalGearCheckForm from '../ModalGearCheckForm';
import { grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        height: 110,
        flexGrow: 1,
        maxWidth: 800,
        textAlign: "left",
        padding: 1,
        paddingTop: '0%',
        paddingBottom: '0%',
        paddingLeft: 1,
        paddingRight: 1,
    },
    content: {
        padding: 20,
    },
    mainCard: {
        maxWidth: 120,
        padding: 0,
        justifyContent: 'center',
        maxHeight: 180,
        
    },
    media: {
        paddingTop: '95.25%', // 16:9
        maxHeight: 50,
    },
});

export default function RecursiveTreeView( props) {
    const classes = useStyles();

    const getStatusCheckValid = (node) => {
        if (node.OutOfService === true) {
            return { color: red[800], fontSize: '20px' };
        }
        else if (node.NeedsRepair || node.NeedsResupply || parseInt(node.Quantity) < parseInt(node.MinimumQuantity)) {
            return { color: red[800], fontSize: '20px' };
        }
        else { return { color: grey[900], fontSize: '20px' }; };
    };

    const getErrorFont = () => {
        return { color: red[900] };
    }

    // tricky logic here for determining how to display the node title and
    // quantities based on status of the node.
    const formatNameAndQuantity = (node) => {
        var nodeName = node.Name;
        var sMinQuant = node.MinimumQuantity === "0" || node.MinimumQuantity === "1" ? "" : " (min:" + node.MinimumQuantity + ")";
        if (node.Quantity < 2) {
            if (node.ItemType === "2")
                return nodeName + " (1 box)" + sMinQuant;
            else if (node.ItemType === "3")
                return nodeName + " (1 pkg)" + sMinQuant;
            else
                return parseInt(node.Quantity) < parseInt(node.MinimumQuantity) ? nodeName + " (" + node.Quantity + ")" + sMinQuant : nodeName;
        }
        else {
            if (node.ItemType === "2")
                return nodeName + " (" + node.Quantity + " boxes)" + sMinQuant;
            else if (node.ItemType === "3")
                return nodeName + " (" + node.Quantity + " pkgs)" + sMinQuant;
            else
                return nodeName + " (" + node.Quantity + ")" + sMinQuant;
        }
    };

    const formatStatusComment = (node) => {
        if (node.ExpirationDate === null) {
            if (node.GearCheckStatus !== "ok") {
                return <><br /><font style={getErrorFont()}><i>-{node.GearCheckStatus}- {node.GearCheckComment}</i></font><br /></>;
            }
            else {
                if (node.GearCheckComment !== "")
                    return <><br /><i>{node.GearCheckComment}</i><br /></>;
            }
        }
        else {
            var d = new Date();
            var dExpDate = new Date(node.ExpirationDate);
            if (dExpDate < d) {
                var dtFormat = (dExpDate.getMonth() + 1) + "/" + dExpDate.getDate() + "/" + dExpDate.getFullYear();
                return <><br /><font style={getErrorFont()}><i>-expired {dtFormat}- {node.GearCheckStatus !== "ok" ? "-" + node.GearCheckStatus + "-" : ""} {node.GearCheckComment}</i></font><br /></>;

            }
            else if (dExpDate < d.setDate(d.getDate() + 14)) {
                var dtFormat2 = (dExpDate.getMonth() + 1) + "/" + dExpDate.getDate() + "/" + dExpDate.getFullYear();
                return <><br /><font style={getErrorFont()}><i>-expiring {dtFormat2}- {node.GearCheckStatus !== "ok" ? "-" + node.GearCheckStatus + "-" : ""} {node.GearCheckComment}</i></font><br /></>;

            }
            else if (node.GearCheckStatus !== "ok")
                return <><br /><font style={getErrorFont()}><i>-{node.GearCheckStatus}- {node.GearCheckComment}</i></font><br /></>;
        }
    };

    const insertNewline = (str) => {
        var result = str.split('\n').map(function (item, key) {
            return (
                <div key={key}>
                    {item}
                </div>
            )
        })
        return result;
    };

    const formatInstructions = (node) => {
        if (node.GearCheckInstructions && node.GearCheckInstructions.length > 2) {
            return <div> {insertNewline(node.GearCheckInstructions)} </div>
        }
    };

    const rowClick = (id, node, e) => {
        e.preventDefault();
        props.focusClick(id, node, e);
    };

    const getImageCard = (node) => {
        if (props.instance === null || props.instance  === undefined)
            return null;

        return (
            <Card className={classes.mainCard} hidden={true}>
                <CardMedia
                    className={classes.media}
/*                    image={utilLib.CdnImageUrl(props.instance.state.customerCode, node.id)} */
                    title={node.Name}

                />
            </Card>
        );
    }

    const getNameRow = (node, name, id) => {
        return (
            <Grid container column justify="center" alignItems="flex-start" style={{ borderWidth: "medium", borderColor: "gainsboro" }}>
                <Grid item xs={7} md={8} onClick={e => rowClick(id, node, e)} >
                    <font style={getStatusCheckValid(node)}>{formatNameAndQuantity(node)}</font>
                    {getImageCard(node)}
                    {formatStatusComment(node)}
                    <i>{formatInstructions(node)}</i><br /><br/><br/>
                </Grid>
                <Grid item xs={5} md={3}>
                    <ModalGearCheckForm inventoryId={node.id} needsRepair={node.NeedsRepair} roles={props.roles}
                        outOfService={node.OutOfService} needsResupply={node.NeedsResupply} gearCheckComment={node.GearCheckComment}
                        inventoryNode={node} gearCheckStatus={node.GearCheckStatus} itemType={node.ItemType} gearCheckInstructions={node.GearCheckInstructions} />
                </Grid>
                <Grid item xs={12} md={11}>
                    <Divider />
                    <Divider />
                    <Divider />
                </Grid>
            </Grid>
        );
    };

    const renderTree = (node) => (
        <TreeItem key={node.id} nodeId={`${node.id}`} label={GetTreeLabel(node, node.Name, node.id)} classes={classes.root} >
            {Array.isArray(node.ChildNodesExt) ? node.ChildNodesExt.map((node) => renderTree(node)) : null}
         </TreeItem>
    );

    return (
        <Grid container alignItems="flex-start">
            <Grid item xs={12} md={8}>
                <TreeView
                    className={classes.root}
                    
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={[props.inventoryNode.id.toString()]}
                defaultExpandIcon={<ChevronRightIcon/>}
                >
                {renderTree(props.inventoryNode)}
            </TreeView>
            </Grid>
        </Grid>
    );

    function GetTreeLabel(node, name, id) {
        const treeItemClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        return (<div onClick={(e) => treeItemClick(e)} classes={classes.root}>
            {getNameRow(node, name, id)}
        </div>
        );
    };
};