import React from 'react';
import MaterialTable from 'material-table';
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import { TextField } from '@material-ui/core';
import * as httpLib from '../library/httpRequestLibrary';

export default function CustomerAdminUsersTable(props) {

    const [state, setState] = React.useState({
        data: props.users,
    });

    return (
        <MaterialTable
            options={{
                addRowPosition: "first",
                pageSize: 100,
                paging: false,
                exportButton: true
            }}
            columns={
                [
                    { title: 'Email', field: 'Email', searchable: true, initialEditValue: "email" },
                    {
                        title: 'Password', field: 'Password',
                        initialEditValue: "x",
                        editComponent: props => (
                            <TextField
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            />)
                    },
                    { title: 'UserId', field: 'UserId', hidden: true },
                    { title: 'UserName', field: 'UserName', hidden: true },
                    { title: 'Admin', field: 'Admin' },
                    { title: 'Edit', field: 'Edit' },
                    { title: 'Delete', field: 'Delete' },
                    { title: 'Reports', field: 'Reports' },
                    { title: 'OosReport', field: 'OosReport' },
                    { title: 'View', field: 'View' },
                    { title: 'SetMinimumQuantity', field: 'SetMinimumQuantity' },
                    { title: 'MoveCopy ', field: 'MoveCopy' },
                ]
            }
            icons={{
                Add: AddBox,
                Check: Check,
                Clear: Clear,
                Delete: DeleteOutline,
                DetailPanel: ChevronRight,
                Edit: Edit,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                ResetSearch: Clear,
                Search: Search,
                SortArrow: ArrowUpward,
                ThirdStateCheck: Remove,
            }}

            title="GearHawk Customer User Admin"
            data={state.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            const pwdOk = validateFieldsForSubmission(newData);
                            if (!pwdOk) {
                                alert("You must enter a valid email and a password when creating a new user.");
                                return;
                            }
                            postNewUserAndRefresh(newData);
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            validateFieldsForSubmission(newData);
                            postUpdateAndRefresh(newData);
                            resolve();
                            if (oldData) {
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            }
                        }, 600);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
            }}
        />
    );
};

async function postUpdateAndRefresh(newData) {
    const data = httpLib.post("customeradmin?action=update&objType=user", newData);
    return data;
};

async function postNewUserAndRefresh(newData) {
    const data = httpLib.post("customeradmin?action=add&objType=user", newData);
    return data;
};

function validateFieldsForSubmission(data) {
    var emailAddr = data.Email;
    if (emailAddr === undefined || emailAddr.length < 4) {
        alert("You must enter a valid email address.");
        return false;
    };

    var pass = data.Password;
    if (pass !== undefined && pass.length > 0) {
        ;
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,12})");
        var test = strongRegex.test(pass);
        if (test) {
            return true;
        } else {
            alert('The password must have at least 8 characters, at least one upper case letter, at least one lower case letter, at least one number and at least one special character.');
            return false;
        }
    }
    else {
        return true;
    }
};