import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ChildInventoryCard from './ChildInventoryCard';

export default class NodeTableRow extends Component { 

    handleClick(e){
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(e);
    }

    handleImageClick(item, e){
        this.props.onClick(item, e);
    }

    // Modify this function carefully. It's used to prevent re-rendering of the inventory children and their cards/images.
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.id === nextProps.id && this.props.globalImageMode === nextProps.globalImageMode) {
            return false;
        }
         else {
             return true;
        }
    }

    render() {
        const key = this.props.id;
        const name = this.props.name;
        const parentName = this.props.parentName;
        const description = this.props.description;
        const customerCode = this.props.customerCode;
        const imageUrl = this.props.baseImageUrl;
        const children = this.props.children;
        const globalImageMode = this.props.globalImageMode;
        const isInventory = this.props.isInventory;
        const needsRepair = this.props.needsRepair;
        const needsResupply = this.props.needsResupply;
        const outOfService = this.props.outOfService;
        const gearCheckStatus = this.props.gearCheckStatus;
        const gearCheckComment = this.props.gearCheckComment;
        const gearCheckInstructions = this.props.gearCheckInstructions;
        const itemType = this.props.itemType;
        const inventoryNode = this.props.inventoryNode;
        const roles = this.props.roles;

        let ch;
        ch = <td></td>
        if (this.props.searchMode  && Array.isArray(children)) {
        /* displays the results of a search */
            var count = 1;
            ch = <>
                {children.map(child =>
                    <Typography color="inherit" align="left" component="span" key={child.id}>
                        {count++ === 1 ? <a key={child.id} onClick={e => this.handleImageClick(child, e)} href="#">{child.Name}</a>
                            :  <a key={child.id} onClick={e => this.handleImageClick(child, e)} href="#"> | {child.Name}</a>}
                    </Typography>
                 )}
            </>
            return <>
                    <Grid key={key} container spacing={1} border={0} direction="row" margin="normal" shadows="none">
                    <Grid item xs={12} border={0}>
                        {ch}
                        <ChildInventoryCard roles={roles} inventoryId={key} key={key} inventoryName={name} parentName={parentName} description={description}
                            imageUrl={imageUrl + customerCode + "_" + key + "_t?time=" + new Date().getTime()} globalImageMode={globalImageMode}
                            isInventory={isInventory} needsRepair={needsRepair} needsResupply={needsResupply} outOfService={outOfService} openEdit={e => this.handleClick(e)} onClick={e => this.handleClick(e)}
                            gearCheckStatus={gearCheckStatus} gearCheckComment={gearCheckComment} gearCheckInstructions={gearCheckInstructions} itemType={itemType}
                            inventoryNode={inventoryNode}
                            />
                           
                        </Grid>
                </Grid>
                <br/>
                </>;
         }
        else {
            /* displays child items in inventory */
            return <Grid container spacing={1} border={0}  direction="row" margin="normal" shadows="none">
                 <Grid item xs={12} border={0}>
                    <ChildInventoryCard roles={roles} inventoryId={key} key={key} inventoryName={name} parentName={parentName} description={description}
                        imageUrl={imageUrl + customerCode + "_" + key + "_t?time=" + new Date().getTime()} globalImageMode={globalImageMode}
                        isInventory={isInventory} needsRepair={needsRepair} needsResupply={needsResupply} outOfService={outOfService} openEdit={e => this.handleClick(e)} onClick={e => this.handleClick(e)}
                        gearCheckStatus={gearCheckStatus} gearCheckComment={gearCheckComment} gearCheckInstructions={gearCheckInstructions} itemType={itemType}
                        inventoryNode={inventoryNode}
                    />
                 </Grid>
            </Grid>
        };
    };
};