import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { NavLink } from 'reactstrap';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import NodeTableRow from '../NodeTableRow';
import 'react-image-lightbox/style.css';
import Typography from '@material-ui/core/Typography';
import {
    Button, ButtonGroup, Card, Radio, RadioGroup, CardContent, Checkbox, FormLabel,
    FormControlLabel, Grid, Input, TextField, Breadcrumbs
} from '@material-ui/core';
import '../Reports.css';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import GutterLeft from '../gutter/GutterLeft';
import GutterRight from '../gutter/GutterRight';
import MainItemAudit from '../audit/MainItemAudit';
import MoveCopy from '../moveCopy/MoveCopy';
import RecursiveTreeView from '../treeView/RecursiveTreeView';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import * as eventLib from '../library/eventLibrary';
import * as utilLib from '../library/utitlityLibrary';
import BreadcrumbGH from '../breadcrumbGH/BreadcrumbGH';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import InventoryTable from '../InventoryTable';
import * as httpRequestLibrary from '../library/httpRequestLibrary';
import * as mode from '../library/mode';
import TopMenu from '../topMenu/TopMenu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dashboard from '../dashboard/Dashboard';
import SearchResultsTable from '../searchResults/SearchResultsTable';
import { Link as MuiLink } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import GlobalTable from '../globalTable/GlobalTable';
import HomeReadOnly from '../home/HomeReadOnly';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.key = props.key;
        let inv = { Id: -1 };
        let srchVals = { node_id: -1 };
        this.state = {
            inventory: inv, customerCategories: "", reportInventoryId: "-1", inventoryReportJson: "", uiTreeData: "", searchCriteria: undefined,
            searchResults: srchVals, breadcrumb: [], rigPctComplete: "0%",
            hack_inventoryName: "NA", hack_inventoryDescription: "NA", hack_isInventory: true,
            hack_needsRepair: false, hack_outOfService: false, hack_needsResupply: false, hack_gearCheckComment: "ok", hack_categories: [],
            modal: false, photoIndex: 0, customerCode: "NA", refreshImage: false, imageUrl: "", parentImageUrl: "",
            imageReadyForUpload: false, imageFile: "",
            hack_inventoryExpirationDate: "", hack_inventoryQuantity: "1", hack_inventoryMinimumQuantity: "1", hack_displayOrder: "50", hack_itemType: "1",
            hack_gearCheckInstructions: "NA", hack_globalDescription: false, hack_globalInstructions: false, hack_globalCategories: false,
            fullTreeFromRoot: { Id: -1 }, report_spreadsheetTitle: "",
            /* state mode flags */
            loading: true, inventoryLoading: true, treeLoading: false, reportLoading: false, gearCheckSummaryView: false,
            treeviewMode: false, reportMode: false, editMode: false, addMode: false, customerAdminMode: false,
            searchMode: false, moveCopyMode: false, inventoryMode: false, globalImageMode: "img", dashboardMode: true,
            /* report mode flags */
            reportType: "status", hack_rptTypeRadioBtn: "status"
        };
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleGearCheckInstructionsChange = this.handleGearCheckInstructionsChange.bind(this);
        this.handleItemTypeChange = this.handleItemTypeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleExpirationDateChange = this.handleExpirationDateChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleMinimumQuantityChange = this.handleMinimumQuantityChange.bind(this);
        this.handleDisplayOrderChange = this.handleDisplayOrderChange.bind(this);
        this.handleImageUploadClick = this.handleImageUploadClickAsync.bind(this);
        this.handleGearCheckCommentChange = this.handleGearCheckCommentChange.bind(this);
        this.toggleModalImage = this.toggleModalImage.bind(this);
        this.input = React.createRef();
        this.images = [""];
        this.roles = "NA";
        this.maxListHeight = 200;
    };

    toggleModalImage() {
        this.setState({ modal: this.state.modal === true ? false : true })
    };

    /* a required virtual call for React. */
    async componentDidMount() {
        var authenticated = await authService.isAuthenticated();
        if (authenticated === undefined || authenticated === false) {
            this.setState({ loading: false, inventoryLoading: false });
        }
        this.loadNodeAsync(-1);
    };

    // IMPORTANT: this function is heavily involved
    // in proper state and load management!
    // Important: modify carefully and do regression testing
    // on searches and breadcrumb clicks!
    // TODO: need to fix the incoming args: for a search
    // the valid object is in the event (not a real event),
    // but for the breadcrumb, the child data is used.
    async getNode(nodeId, event) {
        if (this.state.inventoryLoading === true)
            return null;
        this.setState({ inventoryLoading: true });
        let id;
        if (event !== null && event !== undefined && event.Id !== undefined) {
            id = event.Id;
        }
        else {
            id = nodeId;
        }
        if (this.state.searchMode === true) {
            this.setState({
                inventoryMode: true, searchMode: false, treeViewMode: false,
                editMode: false, addMode: false, customerAdminMode: false,
                moveCopyMode: false
            });
        }
        else if (this.state.treeviewMode) {
            mode.enableTreeViewModeAsync(this, id);
        };
        /// DO NOT 'AWAIT' on this load call! IT REPAINTS THE WHOLE PAGE IF YOU DO!
        this.loadNodeAsync(id);

        if (this.state.reportMode) {
            this.handleReportOptionsControlClick(this.state.hack_rptTypeRadioBtn, id);
        }
        this.setState({ inventoryLoading: false });
    };

    updateNode(inventoryJson) {
        inventoryJson.Description = this.state.hack_inventoryDescription;
        inventoryJson.GearCheckInstructions = this.state.hack_gearCheckInstructions;
        inventoryJson.ItemType = this.state.hack_itemType;
        inventoryJson.Name = this.state.hack_inventoryName;
        inventoryJson.IsInventory = this.state.hack_isInventory;
        inventoryJson.NeedsRepair = this.state.hack_needsRepair;
        inventoryJson.NeedsResupply = this.state.hack_needsResupply;
        inventoryJson.OutOfService = this.state.hack_outOfService;
        inventoryJson.GearCheckDateTime = new Date();
        inventoryJson.GearCheckComment = this.state.hack_gearCheckComment;
        inventoryJson.Quantity = this.state.hack_inventoryQuantity;
        inventoryJson.MinimumQuantity = this.state.hack_inventoryMinimumQuantity;
        inventoryJson.DisplayOrder = this.state.hack_displayOrder;
        inventoryJson.Categories = this.state.hack_categories;
        inventoryJson.GlobalDescription = this.state.hack_globalDescription;
        inventoryJson.GlobalInstructions = this.state.hack_globalInstructions;
        inventoryJson.GlobalCategories = this.state.hack_globalCategories;
        if (this.state.hack_inventoryExpirationDate === null || this.state.hack_inventoryExpirationDate.length === 0) {
            inventoryJson.ExpirationDate = "1001-02-11";
        }
        else {
            inventoryJson.ExpirationDate = this.state.hack_inventoryExpirationDate;
        }
        if (this.state.hack_outOfService)
            inventoryJson.GearCheckStatus = "oos";
        else if (this.state.hack_needsRepair)
            inventoryJson.GearCheckStatus = "repair";
        else if (this.state.hack_needsResupply) {
            inventoryJson.GearCheckStatus = "resupply";
        }
        else
            inventoryJson.GearCheckStatus = "ok";
        inventoryJson.Action = 2; //update
        if (this.state.addMode === true) {
            this.postNodeUpdateAndRefreshAsync(inventoryJson, inventoryJson.ParentId);
        }
        else {
            this.postNodeUpdateAndRefreshAsync(inventoryJson, inventoryJson.id);
        };

        if (this.state.imageReadyForUpload) {
            this.uploadImageAsync(inventoryJson);
        };
    };

    handleDeleteItem(inventoryJson) {
        if (inventoryJson.ChildNodesExt != null && inventoryJson.ChildNodesExt.length > 0) {
            alert("You cannot delete " + inventoryJson.Name + ". Delete or move all child items first.");
            return;
        }
        else {
            if (window.confirm("!! Are you sure you want to delete " + inventoryJson.Name + "?")) {
                inventoryJson.Action = 3;
                this.postNodeUpdateAndRefreshAsync(inventoryJson, inventoryJson.ParentId);
            }
        }
    };

    showAlertButton() {
        alert("show alert button... " + this.state.searchMode);
        return this.state.inventory.id;
    }

    cancelEdit(data) {
        this.setState({ editMode: false, treeviewMode: false, reportMode: false, moveCopyMode: false });
        this.loadNodeAsync(data.id);
    };

    titleClickHandler = () => {
        this.setState({ editMode: false, moveCopyMode: false });
        this.loadNodeAsync(-1);
    };

    handleNameChange(event) {
        this.setState({ hack_inventoryName: event.target.value });
    };

    handleExpirationDateChange(event) {
        this.setState({ hack_inventoryExpirationDate: event.target.value });
    };

    handleQuantityChange = (event) => {
        const re = /^[0-9\b]+$/; // integers only
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ hack_inventoryQuantity: event.target.value })
        }
        else {
            alert("You can only enter a number for quantity");
        }
    };

    handleMinimumQuantityChange = (event) => {
        const re = /^[0-9\b]+$/; // integers only
        if (event.target.value === "0") {
            alert("Minimum Quantity must be at least 1");
            return;
        }
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ hack_inventoryMinimumQuantity: event.target.value })
        }
        else {
            alert("You can only enter a number for nimimum quantity");
        }
    };

    handleItemTypeChange = (event) => {
        this.setState({ hack_itemType: event.target.value })
    };

    handleDisplayOrderChange = (event) => {
        const re = /^[0-9\b]+$/; // integers only
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ hack_displayOrder: event.target.value })
        }
        else {
            alert("Display order must be a number from 0 (zero) to 100 (one hundred).");
        }
    };

    handleDescriptionChange(event) {
        this.setState({ hack_inventoryDescription: event.target.value });
    };

    handleGearCheckInstructionsChange(event) {
        this.setState({ hack_gearCheckInstructions: event.target.value });
    };

    handleGearCheckCommentChange(event) {
        this.setState({ hack_gearCheckComment: event.target.value });
    };

    handleSearchClick = (event, cats, options) => {
        var selectedCategories = "";
        var selectedOptions = "";

        selectedCategories = cats.map(category => {
            return category.Name + "";
        }).join(',');

        selectedOptions = options.map(option => {
            return option.name + "";
        }).join(',');

        if (selectedCategories === "" && selectedOptions === "" && this.input.value === "") {
            alert("Choose at least one category, or one status, or enter some text...");
            return;
        }

        mode.enableSearchMode(this);
        this.getSearchResultsAsync(this.state.inventory.id, this.input.value, selectedCategories, selectedOptions);
    };

    hideAdminLink() {
        return this.state.roles && this.state.roles.includes("SuperAdmin") ? false : true;
    };

    async uploadImageAsync(inventoryJson) {
        try {
            if (this.state.loading === true)
                return;
            this.setState({ loading: true });
            let formData = new FormData();
            let myFile = this.state.imageFile;
            if (inventoryJson.id < 1) {
                alert("Cannot upload an image right now...");
                this.setState({ loading: false });
                return;
            }
            formData.append("file", myFile, this.state.customerCode + "_" + inventoryJson.id);
            const token = await authService.getAccessToken();
            const url = "image";
            await fetch(url, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'enctype': 'multipart/form-data'
                },
                body: formData
            });
        }
        catch (error) {
            alert("Problem uploading the image file. Please try again.");
        }
        this.setState({
            imageReadyForUpload: false, imageFile: "", refreshImage: true, editMode: false, addMode: false,
            imageUrl: "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + this.state.customerCode + "_" + inventoryJson.id + "?time=" + new Date().getTime()
        });
        this.loadNodeAsync(inventoryJson.Id);
    };

    async handleImageUploadClickAsync(inventoryJson, event) {
        this.setState({ imageReadyForUpload: true, imageFile: event.target.files[0] });
        return;
    };

    async getSearchResultsAsync(inventoryId, searchValue, categories, status) {
        try {
            const objSearchCriteria = { Name: this.state.inventory.Name, Id: inventoryId, Text: searchValue, Categories: categories, Status: status };
            this.setState({ searchCriteria: objSearchCriteria });
            if (this.state.loading === false) {
                this.setState({ loading: true });
            };
            var data = await httpRequestLibrary.request("fullSearch?inventoryId=" + inventoryId + "&searchValue=" + searchValue + "&categories=" + categories + "&status=" + status);
            var searchResultsJson = JSON.parse(data[0]);
            if (searchResultsJson.length > 0) {
                this.setState({ loading: false, searchResults: searchResultsJson });
            }
            else {
                this.setState({ loading: false, searchResults: "", searchMode: false });
                alert("No results found under " + this.state.inventory.Name + ". Try modifying your search.");
            };
        }
        catch (error) {
            this.setState({ loading: false })
            alert("error..." + error);
        };
    };

    render() {
        var authenticated = authService.isAuthenticated();
        if (authenticated === false) {
            return <Redirect to={"/authentication/login"} />;
        }

        if (this.state.loading === true) {
            return <div><em>Loading..</em></div>;
        }
        // This check is important. Redirect to login if the conditions below are met.
        else {
            if (this.state.inventory.Id === -1 && this.state.addMode === false && this.state.searchMode === false) {
                return <Redirect to={"/authentication/login"} />;
            }
            else {
                return (
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center" >
                        <Grid item container spacing={0} direction="row" justify="center" alignItems="center">
                            <Grid item xs={false} sm={false} >
                                <GutterLeft />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {this.renderSpaPage(this.state.roles, this.state.inventory, this.state.breadcrumb,
                                    this.state.editMode, this.state.searchMode, this.state.moveCopyMode, this.state.customerAdminMode, this.state.searchResults)}
                            </Grid>
                            <Grid item xs={false} sm={false} >
                                <GutterRight />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        }
    };

    updateCheckbox(evt) {
        let nm = evt.target.name;
        this.setState({ [nm]: evt.target.checked });
    };

    formatExpirationDate(strDate) {
        if (strDate === null)
            return "";
        if (strDate === undefined || strDate.startsWith("0001-"))
            return "";
        else
            return strDate.substring(0, 10);
    };

    formatChildItemsLabel(inventoryJson) {
        if (inventoryJson.ChildNodesExt === undefined || inventoryJson.ChildNodesExt.length === 0) {
            return <span>&nbsp;</span>
        }
        else {
            return <span><i > ------------- {inventoryJson.ChildNodesExt.length + " " + inventoryJson.Name}&nbsp;Items--------------</i><br /><br /></span>
        };
    };

    // only render() should call this function...
    renderSpaPage(roles, inventoryJson, breadcrumb, editMode, searchMode, moveCopyMode, customerAdminMode, searchResultsJson) {
        var breadcrumbJson = JSON.parse(breadcrumb);
        return (
            <div style={{ padding: 0 }} >
                {/* ========================= top menu  =============================================================================================================*/}
                { this.renderTopMenu(inventoryJson, roles, searchResultsJson)}
                {/* ========================= edit mode  =============================================================================================================*/}
                {this.renderEditMode(inventoryJson, roles, editMode)}
                {/* ========================= read-only mode =============================================*/}
                {this.renderReadOnlyMode(inventoryJson, breadcrumbJson, roles, editMode, moveCopyMode)}
                {/* ========================= report mode =============================================*/}
                {this.renderReportMode(inventoryJson, breadcrumbJson)}
                {/* ========================== new search mode ==========================================*/}
                {this.renderSearchMode(searchResultsJson, breadcrumbJson)}
                {/* ========================== rig check (treeview) mode ==========================================*/}
                {this.renderRigCheckMode(inventoryJson, breadcrumbJson)}
                {/* =========================== dashboard mode  =============================================================================================================*/}
                {this.renderDashboardMode(inventoryJson, roles)}
                {/* =========================== copy mode =============================================*/}
                {this.renderCopyMoveItem(inventoryJson, roles)}
                {/* =========================== childInventory items =============================================*/}
                <br />
                {this.renderChildInventoryItems(inventoryJson)}
                {/* =========================== super admin =============================================*/}
                <NavLink hidden={this.hideAdminLink()} tag={Link} to="/admin" style={{ width: "30%", padding: "0", margin: "0" }}>
                    <IconButton aria-label="delete" hidden={!this.state.inventoryMode} >
                        <StarTwoToneIcon fontSize="small" color="primary" />
                    </IconButton>
                </NavLink>
            </div >
        );
    };

    renderReportMode(inventoryJson, breadcrumbJson) {
        if (this.state.reportMode === false || this.state.searchMode === true) {
            return null;
        }
        else if (this.state.reportLoading) {
            return <i>Report Loading...</i>
        }
        else {
            var fullPath = "";
            fullPath = breadcrumbJson.map(node => {
                return node.Name + "/";
            }).join('');
            fullPath = fullPath.substring(0, fullPath.length - 1);
            return (
                <div style={{ height: "auto" }} >

                    <Grid container justify="center" align-items="flex-start">
                        <Grid item xs={12} md={4}>

                            <Breadcrumbs maxItems={6} separator="|" aria-label="breadcrumb">
                                {breadcrumbJson.map(item =>
                                    <div><Link variant="subtitle1" tag="a" href="#" to="#" key={item.id} onClick={e => this.getNode(item.id, e)} >{item.Name}</Link></div>
                                )}
                            </Breadcrumbs>

                        </Grid>
                        <Grid item xs={12} md={8} >
                            <Grid container justify="flex-start" align-items="flex-start">
                                <Grid item xs={12} md={12}>

                                    <RadioGroup aria-label="status" name="status1" value={this.state.hack_rptTypeRadioBtn} onClick={evt => this.handleReportOptionsControlClick(evt.target.value, inventoryJson.id)} row >
                                        <FormControlLabel value="status" control={<Radio />} label="Status" checked={this.state.hack_rptTypeRadioBtn === "status"} />
                                        <FormControlLabel value="inventory" control={<Radio />} label="Inventory" checked={this.state.hack_rptTypeRadioBtn === "inventory"} />
                                        <FormControlLabel hidden={true} value="issues" control={<Radio />} label="Issues Report" />
                                        <FormControlLabel hidden={true} value="resupply" control={<Radio />} label="Resupply Report" />
                                    </RadioGroup>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <div>

                        <div id="statusReportDiv" hidden={this.state.hack_rptTypeRadioBtn === "inventory"}></div>
                        <div id="inventoryReportDiv" hidden={this.state.hack_rptTypeRadioBtn === "status"}>
                            <InventoryTable instance={this} rows={this.state.inventoryReportJson.ChildNodesExt} title={fullPath} />
                        </div>

                    </div>

                </div>
            );
        };
    };

    renderDashboardMode(inventoryJson, roles) {
        if (this.state.dashboardMode === false) {
            return null;
        } else {
            return (
                <Dashboard parentInstance={this} inventoryJson={inventoryJson} roles={roles} />
            );
        };
    };

    renderSearchMode(searchResultsJson, breadcrumbJson) {
        if (this.state.searchMode === false) {
            return null;
        } else {
            return (
                <div>
                    <Grid container justify="center" align-items="center" >
                        <Grid item xs={12} md={12} item justify="center" direction="row">
                            <BreadcrumbGH trimBreadcrumbArray={false} loading={this.state.loading} inventoryLoading={this.state.inventoryLoading} getNode={this.getNode.bind(this)} breadcrumbJson={breadcrumbJson} />
                            <Grid item xs={12} md={12} item justify="center" direction="row">
                                <Typography align="center">
                                    Search Results For: <b><MuiLink onClick={e => this.getNode(searchResultsJson.id, e)} >{this.state.searchCriteria.Name}</MuiLink></b><br />
                                    Text: {this.state.searchCriteria.Text}<br />
                                    Categories: {this.state.searchCriteria.Categories}<br />
                                    Status: {this.state.searchCriteria.Status}<br />
                                    <Button startIcon={<ArrowBackOutlinedIcon />} onClick={e => this.getNode(searchResultsJson.id, e)}>Back</Button>
                                </Typography>

                                <hr />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} hidden={this.state.globalImageMode === "tbl"}>
                            <div style={{ padding: 0 }} >
                                {this.renderSearchResultCards(searchResultsJson)}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" align-items="center" hidden={this.state.globalImageMode !== "tbl"}>
                        <Grid item xs={12}>
                            <div style={{ padding: 0 }} >
                                {this.renderSearchResultsTable(searchResultsJson)}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        };
    };

    generateSearchCriteriaString() {
        var searchCriteriaString = "";
        if (this.state.searchCriteria.Text !== null)
            searchCriteriaString += this.state.searchCriteria.Text
        if (this.state.searchCriteria.Categories !== null)
            searchCriteriaString += searchCriteriaString.length > 1 ? " " + this.state.searchCriteria.Categories : this.state.searchCriteria.Categories;
        if (this.state.searchCriteria.Status !== null)
            searchCriteriaString += searchCriteriaString.length > 1 ? " " + this.state.searchCriteria.Status : this.state.searchCriteria.Status;
        return searchCriteriaString;
    }

    renderSearchResultsTable(searchResultsJson) {
        if (this.state.globalImageMode === "tbl") {
            return <SearchResultsTable rows={searchResultsJson} title={this.state.searchCriteria.Name} instance={this} criteria={this.generateSearchCriteriaString()} />;
        }
    }

    renderSearchResultCards(searchResultsJson) {
        if (!searchResultsJson)
            return null;
        if (searchResultsJson.node_id === -1)
            return null;
        return (
            <div style={{ width: "100%", padding: "2" }}>
                <div style={{ padding: 0 }}>
                    <Grid container justify="center" direction="row" spacing={2}>
                        {searchResultsJson.map(item =>

                            <Grid item xs={6} md={2} border={2}>
                                <NodeTableRow roles={this.state.roles} children={this.buildParents(item.ParentName)} searchMode={true} fullWidth id={item.id} key={item.id} name={item.Name} parentName={item.Name}
                                    description={item.Description} globalImageMode={this.state.globalImageMode} customerCode={this.state.customerCode}
                                    baseImageUrl="https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/"
                                    isInventory={item.IsInventory} needsRepair={item.NeedsRepair} needsResupply={item.NeedsResupply} outOfService={item.OutOfService}
                                    gearCheckStatus={item.GearCheckStatus} gearCheckComment={item.GearCheckComment} gearCheckInstructions={item.GearCheckInstructions} itemType={item.ItemType} inventoryNode={item}
                                    onClick={e => this.getNode(item.id, e)} />
                            </Grid>

                        )}
                    </Grid>
                </div>
            </div>
        );
    };

    buildParents(ghString) {
        var ghArray = ghString.split('|');
        var nodes = []

        if (Array.isArray(ghArray)) {
            ghArray.map(node => {
                var nodeArray = node.split('^');
                var objNode = { Name: nodeArray[0], Id: nodeArray[1] };
                nodes.push(objNode);
            });
        }
        nodes.pop();
        return nodes;
    }

    renderEditMode(inventoryJson, roles, editMode) {
        if (!editMode) {
            return <></>
        } else {
            return (
                <div hidden={!editMode} style={{ padding: "0px" }}>
                    <Card align="center" style={{ padding: "1px" }}>
                        <CardContent style={{ padding: "1px" }}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Paper style={{ padding: "2px" }} elevation={1}><i>- Details -</i></Paper>
                                    <Grid container style={{ padding: "2px" }} justify="space-around" alignItems="center">
                                        <TextField type="textarea" label="Name" variant="filled" fullWidth style={{ margin: 8 }} value={this.state.hack_inventoryName} onChange={this.handleNameChange} id="nodeNamex" />
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                                        )}>
                                            <TextField type="textarea" variant="filled" multiline={true} label="Description" style={{ margin: 8 }} fullWidth rowsMax={8} id="description" rows={2} value={this.state.hack_inventoryDescription} onChange={this.handleDescriptionChange} />
                                        </Linkify>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                                        )}>
                                            <TextField type="textarea" variant="filled" multiline={true} label="Status Check Instructions" style={{ margin: 8 }} fullWidth rowsMax={8} id="instructions" rows={2} value={this.state.hack_gearCheckInstructions} onChange={this.handleGearCheckInstructionsChange} />
                                        </Linkify>
                                        <Grid container>
                                            <Grid item>
                                                <div style={{ minWidth: 300, marginTop: 2 }}>
                                                    <Autocomplete
                                                        size="small"
                                                        multiple
                                                        id="tags-standard"
                                                        options={this.state.customerCategories}
                                                        getOptionLabel={(option) => option.Name}
                                                        defaultValue={this.state.hack_categories}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label="Categories"
                                                            />
                                                        )}
                                                        onChange={(_, selectedOptions) => {
                                                            if (selectedOptions !== null) this.setState({ hack_categories: selectedOptions })
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="space-around" alignItems="center" >
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.hack_globalDescription} name="hack_globalDescription" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                                label="Global Description"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.hack_globalInstructions} name="hack_globalInstructions" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                                label="Global Instructions"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.hack_globalCategories} name="hack_globalCategories" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                                label="Global Categories"
                                            />
                                        </Grid>
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.hack_itemType}
                                                onChange={this.handleItemTypeChange}
                                            >
                                                <MenuItem value={"1"}>Unit</MenuItem>
                                                <MenuItem value={"2"}>Box</MenuItem>
                                                <MenuItem value={"3"}>Package</MenuItem>
                                            </Select>
                                        </FormControl>&nbsp;&nbsp;&nbsp;
                                            <FormControlLabel
                                            control={<Checkbox checked={this.state.hack_isInventory} name="hack_isInventory" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                            label="Inventory"
                                        />
                                        <TextField type="textarea" label="Display" variant="filled" style={{ width: "20%" }} value={this.state.hack_displayOrder} onChange={this.handleDisplayOrderChange} id="nodeDisplayOrder" />
                                    </Grid>
                                    <div style={{ padding: "2px" }} >
                                        &nbsp;
                                     </div>
                                    <Paper style={{ padding: "2px" }} elevation={2}><i>- Status -</i></Paper>
                                    <Grid container style={{ padding: "4px" }} justify="space-around" alignItems="center" >
                                        {roles.includes("SetMinimumQuantity") ?
                                            <TextField type="textarea" style={{ width: "20%" }} label="Min Quantity" variant="filled" value={this.state.hack_inventoryMinimumQuantity} onChange={this.handleMinimumQuantityChange} id="nodeMinimumQuantity" />
                                            :
                                            <Paper style={{ width: "20%" }} elevation={0}><i>Minimum Quantity: {this.state.hack_inventoryMinimumQuantity}</i></Paper>}
                                        <TextField style={{ width: "20%" }} type="textarea" label="Quantity" variant="filled" value={this.state.hack_inventoryQuantity}
                                            onChange={this.handleQuantityChange} id="nodeQuantity" />
                                        <TextField
                                            id="date"
                                            style={{ width: "40%" }}
                                            label="Expiration"
                                            type="date"
                                            value={this.formatExpirationDate(this.state.hack_inventoryExpirationDate)}
                                            onChange={this.handleExpirationDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid container justify="space-around" alignItems="center" >
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.hack_needsRepair} name="hack_needsRepair" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                            label="Repair"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.hack_outOfService} name="hack_outOfService" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                            label="Out of Svc"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.hack_needsResupply} name="hack_needsResupply" onChange={evt => this.updateCheckbox(evt)} color="primary" />}
                                            label="Resupply"
                                        />
                                        <TextField
                                            margin="dense"
                                            id="name"
                                            label="Status Check Comments"
                                            type="text"
                                            fullWidth
                                            value={this.state.hack_gearCheckComment}
                                            onChange={this.handleGearCheckCommentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <ButtonGroup color="primary" orientation="horizontal" fullWidth={true}>
                                            <Button size="medium" variant="outlined" onClick={e => this.updateNode(inventoryJson)} endIcon={<SaveOutlinedIcon />}>
                                                Save
                                            </Button>&nbsp;
                                                <Button size="medium" variant="outlined" onClick={e => this.cancelEdit(inventoryJson)} endIcon={<CancelOutlinedIcon />}>
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid item xs={12} md={6} >
                                    <div style={{ padding: "2px" }} >
                                        &nbsp;
                                            </div>
                                    <Paper style={{ padding: "2px" }} elevation={0}><i>- Images -</i></Paper>
                                    <Media hidden={inventoryJson.id < 1 ? true : false} object src={this.state.imageUrl} style={{ height: "210px" }} width="auto" alt="- no image -" />
                                    <br />
                                    <FormLabel hidden={inventoryJson.id < 1 ? true : false}><b>Image Upload:&nbsp;</b>
                                    </FormLabel>
                                    <Input hidden={inventoryJson.id < 1 ? true : false} type="file" name="Select Image" id="imageUpload"
                                        onChange={e => this.handleImageUploadClickAsync(inventoryJson, e)} ref={this.input} title="Upload Image" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            )
        }
    };

    renderReadOnlyMode(inventoryJson, breadcrumbJson, roles, editMode, moveCopyMode) {
        if (editMode === true || this.state.reportMode === true || this.state.treeviewMode === true ||
            this.state.SearchMode === true || moveCopyMode === true || this.state.loading === true || this.state.inventoryLoading === true || this.state.dashboardMode === true) {
            return null;
        }
        else {
            return (
                <HomeReadOnly instance={this} roles={roles} inventoryJson={inventoryJson} breadcrumbJson={breadcrumbJson} />
            );
        };
    };

    async loadCustomerCategories() {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch("customeradmin?objType=categories", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response === undefined) {
                alert("error. response is null.");
                this.setState({ loading: false });
            }
            else if (response.status === 401) {
                this.setState({ loading: false });
            }
            else {
                const data = await response.json();
                var customerAdminCategories = JSON.parse(data);
                this.setState({
                    customerCategories: customerAdminCategories
                });
                return customerAdminCategories;
            }
        }
        catch (error) {
            this.setState({ loading: false })
            alert("admin error..." + error);
        };
    };

    renderTopMenu(inventoryJson, roles) {
        if (this.state.loading === true || this.state.inventoryLoading === true || this.state.dashboardMode === true)
            return null;

        return (
            <TopMenu parentInstance={this} inventoryJson={inventoryJson} roles={roles} />
        );
    };

    renderRigCheckMode(inventoryJson, breadcrumbJson) {
        if (this.state.treeviewMode === false || this.state.treeLoading === true ||
            this.state.loading === true || this.state.inventoryLoading === true || this.state.searchMode === true) {
            return null;
        }
        return (this.renderRigCheck(inventoryJson, breadcrumbJson));
    };

    renderChildInventoryItems(inventoryJson) {
        if (this.state.loading === true || this.state.searchMode === true || this.state.inventoryLoading === true ||
            this.state.inventoryMode === false || this.state.editMode === true || this.state.treeviewMode === true ||
            this.state.moveCopyMode === true || this.state.reportMode === true) {
            return null;
        }

        if (this.state.globalImageMode === "tbl")
            return this.renderGlobalTableMode(inventoryJson);
        else {
            return (
                <div align="center" style={{ padding: "0px" }}>
                    <div style={{ padding: 0 }}>
                        <Grid container justify="space-between" direction="row" spacing={2}>
                            {inventoryJson.ChildNodesExt.map(item =>
                                <Grid item xs={6} md={2} key={item.id}>
                                    <NodeTableRow roles={this.state.roles} searchMode={false} fullWidth id={item.id} key={item.id} name={item.Name} parentName={inventoryJson.Name}
                                        description={item.Description} globalImageMode={this.state.globalImageMode} customerCode={this.state.customerCode}
                                        baseImageUrl="https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/"
                                        isInventory={item.IsInventory} needsRepair={item.NeedsRepair} needsResupply={item.NeedsResupply} outOfService={item.OutOfService}
                                        gearCheckStatus={item.GearCheckStatus} gearCheckComment={item.GearCheckComment} gearCheckInstructions={item.GearCheckInstructions} itemType={item.ItemType} inventoryNode={item}
                                        onClick={e => this.getNode(item.id, e)} />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
            );
        };
    };

    renderGlobalTableMode(inventoryJson) {
        return <GlobalTable instance={this} />
    };

    renderCopyMoveItem(inventoryJson, roles) {
        if (!this.state.moveCopyMode)
            return null;
        else {
            return (
                <div width="100%" style={{ padding: "0px", height: "auto" }}>
                    <Grid container alignItems="center" direction="row" justify="space-evenly" style={{ width: "100%" }}>
                        <Grid item xs={12} md={12}>
                            <div >
                                <MoveCopy fullTreeFromRoot={this.state.fullTreeFromRoot}
                                    roles={roles}
                                    inventoryJson={inventoryJson} imageUrl={this.state.imageUrl} inventoryId={this.state.inventory.id}
                                    onClick={() => eventLib.processMoveEvent(this)} onCancel={() => eventLib.processMoveEvent(this)}>
                                </MoveCopy>
                            </div>
                        </Grid>
                    </Grid>
                </div>);
        };
    };

    renderRigCheck(inventoryJson, breadcrumbJson) {
        if (this.state.treeLoading) {
            return "Loading tree...";
        }
        else {
            if (this.state.treeviewMode === true && this.state.gearCheckSummaryView === false && this.state.uiTreeData !== undefined && this.state.uiTreeData !== "") {
                var tree = this.state.uiTreeData;
                return (
                    <>
                        <BreadcrumbGH loading={this.state.loading} inventoryLoading={this.state.inventoryLoading} getNode={this.getNode.bind(this)} breadcrumbJson={breadcrumbJson} />
                        <Grid container alignItems="center" direction="row" justify="space-evenly" style={{ width: "100%" }}>
                            <Grid item xs={12} md={5}>
                                <Grid container alignItems="center" direction="column" justify="center" style={{ width: "66%" }}>
                                    <Grid item>
                                        <Button startIcon={<ArrowBackOutlinedIcon />} onClick={e => inventoryJson.Level > 1 ? this.getNode(inventoryJson.ParentId, e) : this.getNode(inventoryJson.id, e)} >Back</Button><br /><br />

                                        <Typography>
                                            <b> <i>{this.state.rigPctComplete} complete</i></b>
                                        </Typography>

                                    </Grid>
                                </Grid>
                                <RecursiveTreeView instance={this} focusClick={this.loadInventoryFromTree} inventoryNode={JSON.parse(tree)} roles={this.state.roles} />
                            </Grid>
                        </Grid>
                    </>
                );
            };
        };
    };

    loadInventoryFromTree = (id, node, e) => {
        this.setState({ rigPctComplete: "~~~" });
        mode.enableTreeViewModeAsync(this, id);
        this.getNode(node.id, e);
    };

    mainCard_nodeInformationDisplayFormatter(inventoryJson, breadcrumbJson) {
        if (!this.state.inventoryMode)
            return null;

        var fontColor = "green";
        //var dateNow = new Date();
        //var dExpDate = new Date(inventoryJson.ExpirationDate);
        //if (inventoryJson.ExpirationDate !== null && dExpDate < dateNow) {
        //    fontColor = "red";
        //    inventoryJson.GearCheckStatus = "expired";
        //}
        //else if (inventoryJson.ExpirationDate !== null && dExpDate < dateNow.setDate(dateNow.getDate() + 14)) {
        //    fontColor = "red";
        //    inventoryJson.GearCheckStatus = "expiring";
        //}
        if (inventoryJson.GearCheckStatus === "oos" || inventoryJson.GearCheckStatus === "expired" || inventoryJson.GearCheckStatus === "expiring") {
            fontColor = "red";
        }
        else if (inventoryJson.GearCheckStatus === "repair" || inventoryJson.GearCheckStatus === "resupply" ||
            parseInt(inventoryJson.Quantity) < parseInt(inventoryJson.MinimumQuantity)) {
            fontColor = "red";
        }
        return (
            <Grid item xs={12} md={12}>
                <BreadcrumbGH loading={this.state.loading} inventoryLoading={this.state.inventoryLoading} getNode={this.getNode.bind(this)} breadcrumbJson={breadcrumbJson}></BreadcrumbGH>
                <Typography align="center" variant="subtitle2" display="inline">
                    <div name="NameDiv" style={{ textAlign: "center", fontSize: "larger" }}><b>{inventoryJson.Name}</b></div>
                    {inventoryJson.Description.length > 0 ?
                        <div name="DescriptionDiv" >
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                            )}>
                                {utilLib.replaceNewlineWithHtmlBreak(inventoryJson.Description)}
                            </Linkify>
                        </div>
                        : null
                    }
                    {inventoryJson.Categories.length > 0 ?
                        <div name="CategoryDiv" style={{ textAlign: "center", fontSize: "small" }}>{utilLib.parseCategories(inventoryJson.Categories)}</div>
                        : null}
                    {inventoryJson.GearCheckInstructions.length > 0 ?
                        <div name="instructionsDiv">
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                            )}>
                                <i>Gear Check:</i>&nbsp;{utilLib.replaceNewlineWithHtmlBreak(inventoryJson.GearCheckInstructions)}
                            </Linkify>
                        </div>
                        : null
                    }

                    <div name="LastUpdateDiv">
                        <i>Last Update:</i>&nbsp;
                        {utilLib.getFormattedDateTime(inventoryJson.GearCheckDateTime)}
                    </div>
                    <div name="quantityDiv" hidden={this.showQuantityDiv(this.state.hack_isInventory)}>
                        {this.formatQuantity()}
                        {this.formatMinimumQuantity(this.state.hack_inventoryMinimumQuantity, this.state.hack_isInventory)}
                    </div>
                    <div name="expirationDateDiv">
                        {this.mainCard_NodeExpirationDateFormatter()}
                    </div>
                    <div name="statusDiv" hidden={inventoryJson.GearCheckStatus.length === 0} >
                        <i>Status:&nbsp;</i><font color={fontColor}>-<b>{inventoryJson.GearCheckStatus}</b>-</font>
                        {this.mainCard_NodeStatusCommentFormatter(fontColor, inventoryJson)}<br />
                    </div>
                    <MainItemAudit inventoryJson={inventoryJson} />
                </Typography>
            </Grid >
        )
    };

    showQuantityDiv(isInventory) {
        if (!isInventory)
            return true;
        return false;
    };

    formatQuantity() {
        if (this.state.hack_isInventory) {
            if (parseInt(this.state.hack_inventoryQuantity) < parseInt(this.state.hack_inventoryMinimumQuantity)) {
                return <><font color='red'><i>Quantity:&nbsp;</i>{this.formatItemType(this.state.hack_inventoryQuantity, this.state.hack_itemType)}</font>&nbsp;&nbsp;</>;
            }
            else
                return <><i>Quantity:&nbsp;</i>{this.formatItemType(this.state.hack_inventoryQuantity, this.state.hack_itemType)}&nbsp;&nbsp;</>;
        }
        else
            return "";
    };

    formatMinimumQuantity(strQuantity, isInventory) {
        if (!isInventory || strQuantity === "0" || strQuantity === "1")
            return "";
        else {
            if (parseInt(this.state.hack_inventoryQuantity) < parseInt(this.state.hack_inventoryMinimumQuantity)) {
                return <i><font color='red'>Minimum:&nbsp; {strQuantity} </font></i>;
            }
            else
                return <i>Minimum:&nbsp;{strQuantity} </i>;
        }
    };

    formatItemType(iQuantity, iType) {
        if (iType === "2") {
            return iQuantity > 1 ? iQuantity + " boxes" : iQuantity + " box";
        }
        else if (iType === "3")
            return iQuantity > 1 ? iQuantity + " pkgs" : iQuantity + " pkg";
        else {
            return iQuantity;
        }
    };

    mainCard_NodeStatusCommentFormatter(fontColor, inventoryJson) {
        if (inventoryJson.GearCheckComment === "") {
            return <></>
        }
        else {
            return <> <i><font color={fontColor}>{inventoryJson.GearCheckComment}</font></i></>
        }
    };

    mainCard_NodeExpirationDateFormatter() {
        if (this.state.hack_inventoryExpirationDate === null || this.state.hack_inventoryExpirationDate === undefined || this.state.hack_inventoryExpirationDate.startsWith("0001-")) {
            return <></>;
        }
        else {
            var dtFix = new Date(this.state.hack_inventoryExpirationDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
            dtFix = dtFix.substring(0, dtFix.indexOf(" ") - 1);
            var d = new Date();
            var dExpDate = new Date(this.state.hack_inventoryExpirationDate);
            if (dExpDate < d)
                return <><i><font color='red'>Expired:&nbsp; {dtFix}</font></i></>;
            else if (dExpDate < d.setDate(d.getDate() + 14)) {
                return <><i><font color='red'>Expiring:&nbsp; {dtFix}</font></i></>;
            }
            else
                return <><i>Expires:</i>&nbsp; {dtFix}</>;
        }
    };

    searchResultsFormatter(searchResultsJson) {
        if (!this.state.searchMode)
            return null;
        return (
            <div style={{ width: "100%", padding: "2" }}>
                <div style={{ padding: 0 }}>
                    <Grid container justify="space-between" direction="row" spacing={2}>
                        {searchResultsJson.map(item =>
                            <Grid item xs={12} md={3} key={item.id}>
                                <NodeTableRow roles={this.state.roles} children={item.ChildNodesExt} searchMode={true} fullWidth id={item.id} key={item.id} name={item.Name} parentName={item.Name}
                                    description={item.Description} globalImageMode={this.state.globalImageMode} customerCode={this.state.customerCode}
                                    baseImageUrl="https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/"
                                    isInventory={item.IsInventory} needsRepair={item.NeedsRepair} needsResupply={item.NeedsResupply} outOfService={item.OutOfService}
                                    gearCheckStatus={item.GearCheckStatus} gearCheckComment={item.GearCheckComment} gearCheckInstructions={item.GearCheckInstructions} itemType={item.ItemType} inventoryNode={item}
                                    onClick={e => this.getNode(item.id, e)} />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>
        );
    };

    async loadNodeAsync(inventoryId) {
        try {
            if (this.state.inventoryLoading === false) {
                this.setState({ inventoryLoading: true });
            };
            this.setState({ photoIndex: 0 });
            const data = await httpRequestLibrary.request("inventoryhome?id=" + inventoryId);
            var inventoryJson = JSON.parse(data[0]);
            this.images = ["https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + inventoryJson.CustomerCode + "_" + inventoryJson.id,
            "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + inventoryJson.CustomerCode + "_" + inventoryJson.ParentId];
            this.setState({
                roles: data[2],
                inventory: inventoryJson, reportInventoryId: inventoryJson.id, breadcrumb: data[1], inventoryLoading: false, loading: false, customerCode: inventoryJson.CustomerCode,
                hack_inventoryName: inventoryJson.Name, hack_inventoryDescription: inventoryJson.Description, hack_gearCheckInstructions: inventoryJson.GearCheckInstructions,
                hack_itemType: inventoryJson.ItemType, hack_isInventory: inventoryJson.IsInventory, hack_needsRepair: inventoryJson.NeedsRepair,
                hack_needsResupply: inventoryJson.NeedsResupply, hack_outOfService: inventoryJson.OutOfService, hack_gearCheckComment: inventoryJson.GearCheckComment,
                hack_inventoryQuantity: inventoryJson.Quantity, hack_inventoryMinimumQuantity: inventoryJson.MinimumQuantity,
                hack_displayOrder: inventoryJson.DisplayOrder, hack_inventoryExpirationDate: inventoryJson.ExpirationDate,
                hack_categories: inventoryJson.Categories, hack_globalCategories: false, hack_globalDescription: false, hack_globalInstructions: false,
                imageUrl: "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + inventoryJson.CustomerCode + "_" + inventoryJson.id + "?time=" + new Date().getTime(),
                parentImageUrl: "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + inventoryJson.CustomerCode + "_" + inventoryJson.ParentId + "?time=" + new Date().getTime(),
                editMode: false, addMode: false, moveCopyMode: false
            });
            return inventoryJson;
        }
        catch (error) {
            this.setState({ loading: false, inventoryLoading: false })
        };
    };

    async postNodeUpdateAndRefreshAsync(nodeToUpdate, idToLoad) {
        if (this.state.loading === false) {
            this.setState({ loading: true });
        };
        const data = await httpRequestLibrary.post("inventoryhome", nodeToUpdate)
        this.setState({ treeviewMode: false, reportMode: false });
        this.loadNodeAsync(idToLoad);
        return data;
    };

    handleReportDownloadClick(id) {
        this.displayReportForDownloadAsync(id);
    };

    async handleSendOOSReportAsync(id) {
        if (window.confirm("!! Are you sure you want to send the OOS Report to all Recipients?")) {
            await httpRequestLibrary.request("report?id=" + id + "&includeHeader=true&sendOosReport=true");
        };
        this.setState({ loading: false });
    };

    async loadFullTreeForMoveCopy() {
        try {
            if (this.state.loading === false) {
                this.setState({ loading: true });
            };
            const data = await httpRequestLibrary.request("tree?id=-1");
            var inventoryJson = JSON.parse(data[0]);
            this.setState({
                loading: false, moveCopyMode: true, fullTreeFromRoot: inventoryJson
            });
            return inventoryJson;
        }
        catch (error) {
            this.setState({ loading: false })
            alert("loadFullTreeFromRoot error..." + error);
        };
    };

    async handleReportOptionsControlClick(rptType, inventoryId) {
        this.setState({ hack_rptTypeRadioBtn: rptType });
        if (rptType === "inventory") {
            await this.loadInventoryReportAsync(inventoryId);
            ReactDOM.render(<></>, document.getElementById("statusReportDiv"));
        }
        else if (rptType === "status") {
            await this.loadStatusReportAsync(inventoryId);
        }
    };

    async loadStatusReportAsync(inventoryId) {
        try {
            if (this.state.reportLoading === true) {
                return;
            }
            this.setState({ reportLoading: true });
            const data = await httpRequestLibrary.request("report?id=" + inventoryId + "&includeHeader=false");
            this.setState({ reportLoading: false });
            ReactDOM.render(this.renderStatusReport(data[0]), document.getElementById("statusReportDiv"));
        }
        catch (error) {
            this.setState({ reportLoading: false })
            alert("loadStatusReportAsync error..." + error);
        };
    };

    renderStatusReport(rpt) {
        return (
            <div>
                <div><Button id="downloadBtnDiv" color="primary" variant="outlined" onClick={e => this.displayReportForDownloadAsync(this.state.reportInventoryId)}>Download</Button>
                </div>
                <div dangerouslySetInnerHTML={{ __html: rpt }} />
            </div>
        );
    };

    async displayReportForDownloadAsync(id) {
        this.setState({ reportLoading: true });
        const data = await httpRequestLibrary.request("report?id=" + id + "&includeHeader=true&sendOosReport=false");
        this.setState({ reportLoading: false });
        this.downloadTxtFile(data[1].toString(), data[0].toString());
    };

    downloadTxtFile = (rptName, rpt) => {
        const element = document.createElement("a");
        const file = new Blob([rpt], { type: 'text/html' });
        element.href = URL.createObjectURL(file);
        element.download = rptName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    async loadInventoryReportAsync(inventoryId) {
        try {
            if (this.state.reportLoading === true) {
                return;
            }
            this.setState({ reportLoading: true });
            const data = await httpRequestLibrary.request("spreadsheet?id=" + inventoryId);
            this.setState({ reportLoading: false, inventoryReportJson: JSON.parse(data[0]) });
        }
        catch (error) {
            this.setState({ reportLoading: false })
            alert("loadInventoryReportAsync error..." + error);
        };
    };

    async loadRigCheckPctCompleteAsync(inventoryId) {
        var data = null;
        try {
            data = await httpRequestLibrary.request("rigCheck?id=" + inventoryId);
            this.setState({ rigPctComplete: JSON.parse(data[0]) });
        }
        catch (error) {
            alert("loadRigCheckPctCompleteAsync error..." + error);
        };
    };
};