import React from 'react';
import * as mode from '../library/mode';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function ImageModeCheckbox(props) {

    const handleImageMode = (event, newMode) => {
        if (newMode !== null)
            mode.toggleGlobalImageMode(props.instance, newMode);
    };

    return (
        <>
            <ToggleButtonGroup
                value={props.instance.state.globalImageMode}
                size="small"
                exclusive
                onChange={handleImageMode}
                aria-label="text alignment"
            >
                <ToggleButton value="img" aria-label="left aligned">
                    IMAGE
                </ToggleButton>
                <ToggleButton value="noImg" aria-label="centered">
                    NO IMAGE
                </ToggleButton>
                <ToggleButton value="tbl" aria-label="right aligned">
                    TABLE
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
};