import React from 'react';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

export default function TitleAndStatusIcons(props) {
    const inventoryName = props.shortenTitle ? props.inventoryName.split(" ").splice(0, 3).join(" ") : props.inventoryName;

    const formatNameAndQuantity = (name, quantity, type) => {
        var sMinQuant = props.minimumQuantity === "0" || props.minimumQuantity === "1" ? "" : " (min:" + props.minimumQuantity + ")";
        if (quantity < 2) {
            if (type === "2")
                return name + " (1 box)" + sMinQuant;
            else if (type === "3")
                return name + " (1 pkg)" + sMinQuant;
            else
                return name + sMinQuant;
        }
        else {
            if (type === "2")
                return name + " (" + quantity + " boxes)" + sMinQuant;
            else if (type === "3")
                return name + " (" + quantity + " pkgs)" + sMinQuant;
            else
                return name + " (" + quantity + ")" + sMinQuant;
        }
    };


    var d = new Date();
    var dExpDate = new Date(props.expirationDate);
    if (props.expirationDate !== null && dExpDate < d.setDate(d.getDate() + 14)){
        return <Typography align="center" variant={props.variantType} style={{ color: red[900] }}  >{formatNameAndQuantity(inventoryName, props.quantity, props.itemType)}&nbsp;</Typography>
    }
    else if (props.outOfService) {
        return <Typography align="center" variant={props.variantType} style={{ color: red[900] }}  >{formatNameAndQuantity(inventoryName, props.quantity, props.itemType)}&nbsp;</Typography>
    }
    else if (props.needsRepair || props.needsResupply) {
        return <Typography align="center" variant={props.variantType} style={{ color: red[900] }}>{formatNameAndQuantity(inventoryName, props.quantity, props.itemType)}&nbsp;</Typography>
    }
    else {
        return <Typography align="center" variant={props.variantType} >{formatNameAndQuantity(inventoryName, props.quantity, props.itemType)}</Typography>
    }
};