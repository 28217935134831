/*
 * Enables the Move/Copy component
*/
export function enableMoveCopyMode(thisInstance) {
    thisInstance.setState({ imageMode: false, moveCopyMode: true });
    thisInstance.loadFullTreeForMoveCopy();
};

/*
 * Handles the save or cancel button click on the CopyMove component.
  */
export function processMoveEvent(thisInstance) {
    thisInstance.setState({ imageMode: true, loading: true });
    thisInstance.loadNodeAsync(thisInstance.state.inventory.ParentId);
};

/*
 * Switches from movecopy mode to inventory mode
  */
export function cancelMoveCopyMode(thisInstance) {
    thisInstance.setState({ moveCopyMode: false, inventoryMode: true});
    if(thisInstance.state.inventory.level === 1)
        thisInstance.loadNodeAsync(thisInstance.state.inventory.id);
    else
        thisInstance.loadNodeAsync(thisInstance.state.inventory.ParentId);
};