import React from 'react';
import MaterialTable from 'material-table';
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import authService from '../api-authorization/AuthorizeService';

export default function AdminUsersTable(props) {
   
    const [state, setState] = React.useState({
        columns: [
            { title: 'Email', field: 'Email', searchable: true },
            { title: 'UserId', field: 'UserId', hidden: true},
            { title: 'Customer', field: 'CustName', searchable: true },
            { title: 'CustomerId', field: 'CustomerId', hidden: true},
            { title: 'Admin', field: 'Admin' },
            { title: 'SuperAdmin', field: 'SuperAdmin' },
            { title: 'Edit', field: 'Edit' },
            { title: 'Delete', field: 'Delete' },
            { title: 'Reports', field: 'Reports' },
            { title: 'OosReport', field: 'OosReport' },
            { title: 'View', field: 'View' },
            { title: 'SetMinimumQuantity', field: 'SetMinimumQuantity' },
            { title: 'MoveCopy ', field: 'MoveCopy' },

        ],
        data: props.users,
    });

    return (
        <MaterialTable
            options={{
                pageSize: 100,
                paging: false,
                exportButton: true
            }}
            icons={{
                Add: AddBox,
                Check: Check,
                Clear: Clear,
                Delete: DeleteOutline,
                DetailPanel: ChevronRight,
                Edit: Edit,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                ResetSearch: Clear,
                Search: Search,
                SortArrow: ArrowUpward,
                ThirdStateCheck: Remove,
            }}

            title="GearHawk User Administration"
            columns={state.columns}
            data={state.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            postUpdateAndRefresh(newData);
                            resolve();
                            if (oldData) {
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            }
                        }, 600);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
            }}
        />
    );
};

async function postUpdateAndRefresh(newData) {
    const token = await authService.getAccessToken();
    const url = "admin";
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Pragma': 'no-cache'
        },
        body: JSON.stringify(newData)
    });
    const data = response.json();
    return data;
};
