import React, { useEffect } from 'react';
import authService from '../api-authorization/AuthorizeService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Toolbar, Grid, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ImageModeButton from '../imageModeButton/ImageModeButton';

export default function SearchbarGH(props) {
    const [custCats, setCustCats] = React.useState([]);
    const [selectedCats, setSelectedCats] = React.useState([]);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [catsLoading, setCatsLoading] = React.useState(true);
    const statusValues = [
        { name: 'ok' },
        { name: 'all non-ok' },
        { name: 'repair' },
        { name: 'resupply' },
        { name: 'oos' },
        { name: 'expired' },
        { name: 'expiring' }
    ];

    useEffect(() => {
        const loadCustomerCategories = async () => {
            try {
                setCatsLoading(true);
                const token = await authService.getAccessToken();
                const response = await fetch("customeradmin?objType=categories", {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                const data = await response.json();
                var customerAdminCategories = JSON.parse(data);
                setCustCats(customerAdminCategories);
                setCatsLoading(false);
            } catch (e) {
                console.log(e);
                setCatsLoading(false);
            }
        };
        loadCustomerCategories();
    }, []);

    function renderCategories() {
        if (catsLoading === true) {
            return null;
        }
        else {
            return (
                <Grid container alignItems="center" justify="flex-end" style={{ width: "100%" }} direction="row">
                    <Grid item xs={6} md={3}>
                        <Autocomplete

                            multiple
                            id="tags-standard"
                            options={custCats}
                            getOptionLabel={(option) => option.Name}
                            defaultValue={selectedCats}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Categories"
                                    margin="normal"
                                />
                            )}
                            onChange={(_, selectedCats) => { setSelectedCats(selectedCats) }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Autocomplete

                            multiple
                            id="tags-standard"
                            options={statusValues}
                            getOptionLabel={(option) => option.name}
                            defaultValue={selectedOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Status"
                                    margin="normal"
                                />
                            )}
                            onChange={(_, selOptions) => { setSelectedOptions(selOptions) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <input onKeyDown={handleKeyDown}
                            style={{ width: '50%', padding: "0", margin: "0" }}
                            name="fname"
                            type="text"
                            ref={myinput => (props.instance.input = myinput)}
                        />
                        <Button style={{ padding: "0", margin: "0" }} variant="outlined" onClick={e => props.instance.handleSearchClick(e, selectedCats, selectedOptions)}>
                            <SearchIcon />
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.instance.handleSearchClick(event, [], []);
        }
    };

    const getSearchBar = () => {
        if (props.instance.state.loading === true || props.instance.state.inventoryLoading === true) {
            return <></>;
        }
        else {
            return (
                <>
                    <Grid container alignItems="flex-start" justify="flex-end" style={{ width: "100%" }} direction="row">
                        <Grid item xs={12} md={3}>
                            <Grid container alignItems="flex-start" justify="flex-start" style={{ width: "100%" }} direction="row">
                                <Grid item xs={12} md={2}>
                                    <ImageModeButton instance={props.instance} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={false} md={3}>
                        </Grid>
                        <Grid item xs={12} md={6}  >
                            {renderCategories()}
                        </Grid>
                    </Grid>
                    <hr style={{ backgroundColor: "#1437E6" }} />
                </>
            );
        }
    };

    return (getSearchBar());
};