import React from 'react';
import authService from '../api-authorization/AuthorizeService';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { green, grey, red} from '@material-ui/core/colors';
import HistoryIcon from '@material-ui/icons/History';
import MainItemAuditTable from './MainItemAuditTable';

export default function MainItemAudit(props) {
    const [hidden, setHidden] = React.useState(true);
    const inventoryNode = props.inventoryJson;
    const [statusCheckValid] = React.useState(true);
    const[ auditJson, setAuditJson] = React.useState("");

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setHidden(true);
    };

    const getStatusCheckValid = (bOk) => {
        if (bOk && inventoryNode.OutOfService)
            return { color: red[900] };
        else if (bOk && (inventoryNode.NeedsRepair || inventoryNode.NeedsResupply))
            return { color: red[900] };
        else return bOk ? { color: green[900] } : { color: grey[600] };
    };

    const auditHistoryClick = async (inventoryId, e) => {
        e.preventDefault();
        e.stopPropagation()
        let response = null;
        
        try {
            const token = await authService.getAccessToken();
            response =  await fetch("inventoryhome?id=" + inventoryId + "&action=1", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response === undefined) {
                alert("error. response is null.");
            }
            else if (response.status === 401) {
                alert("Access denied...");
            }
            else {
                const data = await response.json();
                setAuditJson(JSON.parse(data[0]));
                setHidden(false);
            };
        }
        catch (error) {
            alert("loadAuditData error..." + error);
        };
    };

    return (
        <span onClick={handleClose} style={{ width: "75%" }}>
            <IconButton size="medium" aria-label="issues" onClick={(e) =>auditHistoryClick(inventoryNode.id, e)}>
                <HistoryIcon fontSize="small" style={getStatusCheckValid(statusCheckValid)} />
            </IconButton>
            <Dialog open={!hidden} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="lg">
                <DialogTitle id="form-dialog-title">Status History - {inventoryNode.Name}</DialogTitle>
                <DialogContent>
                    <MainItemAuditTable hidden={hidden} title={inventoryNode.Name} rows={auditJson} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                 </DialogActions>
            </Dialog>
        </span>
    );
};