import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { grey } from '@material-ui/core/colors';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 1,
    },
    media: {
        paddingTop: '95.25%', // 16:9
        maxHeight: 50,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: grey[500],
        hidden: true,
    },
    mainCard: {
        maxWidth: 250,
        padding: 3,
        justifyContent: 'center',
        maxHeight: 380,
    },
    mainCardB: {
        maxWidth: 150,
        padding: 2,
        justifyContent: 'center',
        maxHeight: 330
    }
}));

export default function MainItemCard(props) {
    const classes = useStyles();
    const hideParent = props.level === 1 ? true : false;

    return (
        <div hidden={props.globalImageMode !== "img"} >
            <Grid container justify="center" align-items="center" >
                <Grid item xs={12} md={7} >
                    <Card className={classes.mainCard} raised={true}>
                        <CardMedia
                            className={classes.media}
                            image={props.imageUrl}
                            title={props.inventoryName}
                            onClick={props.onClick}
                        />
                    </Card>
                </Grid>
                <Grid xs={12} md={5} item >
                    <Card hidden={hideParent} className={classes.mainCardB} raised={true}>
                        <CardMedia
                            className={classes.media}
                            image={props.parentImageUrl}
                            title={props.inventoryName}
                            onClick={props.onClick}
                            hidden={props.globalImageMode !== "img"}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};