import React from 'react';

/*
 * Returns a formatted date time in en-us in EST.
 * 
*/
export function getFormattedDateTime(dttm) {
    return new Date(dttm).toLocaleString('en-US', { timeZone: 'America/New_York' });
};

/*
 * Replaces newline (i.e \n) with an html break tag (i.e <br/>)
*/
export function replaceNewlineWithHtmlBreak(str) {
    if (str.endsWith('\n'))
        str = str.slice(0, -1);
    var result = str.split('\n').map(function (item, key) {
        return (
            <span key={key}>
                {item}
                <br />
            </span>
        )
    })
    return result;
};

export function baseCdnImageUrl() {
    return "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/";
};

export function CdnImageUrl(customerCode, key) {
    return "https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/" + customerCode + "_" + key + "_t?time=" + new Date().getTime();
};

export function parseCategories(categories) {
    return (
        <div>
            [{mapCategories(categories)}]
        </div>
    )
};

function mapCategories(categories) {
    let cats = "";
    let i = 0;
    for (i = 0; i < categories.length; i++) {
        cats += categories[i].Name + ", ";
    }
    if (cats.length > 3) {
         cats = cats.substring(0, cats.length - 2);
    }
    return cats;
};

// Takes a string in this format: [nodeName]^[nodeId]|[nodeName]^[nodeId]|...etc..
// This function breaks apart the string to provide a formatted string
// showing the overall location, for instance: Engine 8/Rear Left Cabinet/Rigging Equipment
export function processLocationPath(params) {
    var stringLoc = params.ParentName;
    var arrLocation = stringLoc.split("|");
    if (arrLocation.length === 1) {
        return arrLocation[0].split("^")[0];
    }
    arrLocation.pop();
    var result = "";
    for (var i = 0; i < arrLocation.length; i++) {
        result += arrLocation[i].split("^")[0] + (i === arrLocation.length - 1 ? "" : "/");
    };
    return result;
};