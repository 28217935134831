import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography'

export class About extends Component {
    static displayName = About.name;

    render() {
        return <Grid container justify="space-evenly">
            <Grid item xs={12}>
                <Typography color="inherit" align="center" variant="h5">
                    GearHawk - <i>&copy; 2020</i><br />
                </Typography>
                <Typography color="inherit" align="center" variant="body1">
                    <br/>Created by Ken Gorman<br />
                    For additional information email: gorman.kenneth@gmail.com<br />
                </Typography>
            </Grid>
        </Grid>;
    };
};