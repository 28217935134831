import React, { Component } from 'react';
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@material-ui/core/Typography'
import AdminUsersTable from './AdminUsers';

export class Admin extends Component {
    static displayName = Admin.name;

    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    componentDidMount() {
    };

    loadData(e) {
        this.loadAdminAccessData(1);
    }

    async loadAdminAccessData(num) {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch("admin?id=1", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response === undefined) {
                alert("error. response is null.");
                this.setState({ loading: false });
            }
            else if (response.status === 401) {
                this.setState({ loading: false });
            }
            else {
                const data = await response.json();
                var adminUserAccess = JSON.parse(data[0]);
                this.setState({
                    roles: data[1],
                    userData: adminUserAccess

                });
                this.setState({ loading: false });
                return adminUserAccess;
            }
        }
        catch (error) {
            this.setState({ loading: false })
            alert("admin error..." + error);
        };
    };

    render() {
        return <Grid container justify="space-evenly">
            <Grid item xs={12}>
                <Typography color="inherit" align="center" variant="h5">
                    GearHawk - Administration<br />
                </Typography>
                <br />
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <ButtonGroup color="primary" fullWidth={true} hidden={false}>
                            <Button size="small" variant={"contained"} onClick={e => this.loadData(e)}>Users</Button>
                            <Button size="small" variant={this.state.inventoryMode ? "contained" : "outlined"} >B</Button>
                            <Button variant={this.state.reportMode ? "contained" : "outlined"} size="small" >C</Button>
                            <Button size="small" variant={this.state.gridMode ? "contained" : "outlined"}>D</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <div color="inherit" align="center" variant="body1">
                    {this.state.userData ? <AdminUsersTable users={this.state.userData} /> : <div>no results</div>}
                </div>
            </Grid>
        </Grid>;
    };
};