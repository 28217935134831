import React from 'react';
import { ButtonGroup, Button, Grid } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SearchbarGH from '../searchbarGH/SearchbarGH';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import * as mode from '../library/mode';

export default function TopMenu(props) {

    return (
        <>
            <Grid item xs={12}>
                <ButtonGroup color="primary" fullWidth={true} hidden={false}>
                    <Button disableRipple={true} size="small" variant={props.parentInstance.state.dashboardMode ? "contained" : "outlined"} onClick={e => mode.enableHomeMode(props.parentInstance)}>
                        <HomeOutlinedIcon size="small" />
                    </Button>
                    <Button disableRipple={true} size="small" variant={props.parentInstance.state.inventoryMode ? "contained" : "outlined"} onClick={e => mode.enableInventoryMode(props.parentInstance)}>
                        <AppsOutlinedIcon size="small" />
                    </Button>
                    <Button disableRipple={true} endIcon={<DoneOutlineOutlinedIcon />} variant={props.parentInstance.state.treeviewMode ? "contained" : "outlined"} size="small" onClick={e => { e.preventDefault(); e.stopPropagation(); mode.enableTreeViewModeAsync(props.parentInstance, props.inventoryJson.id);}}>
                       RIG
                    </Button>
                    <Button disableRipple={true} size="small" variant={props.parentInstance.state.reportMode ? "contained" : "outlined"} onClick={e => mode.enableReportMode(props.parentInstance)}>
                        <AssignmentOutlinedIcon size="small" />
                    </Button>
                    <Button disableRipple={true} size="small" disabled={!props.roles.includes("Admin")} variant={props.parentInstance.state.customerAdminMode ? "contained" : "outlined"} onClick={e => mode.enableCustomerAdminModeAsync(props.parentInstance)}>
                        <SettingsOutlinedIcon size="small" />
                    </Button>
                 </ButtonGroup>
            </Grid>
            <Grid item xs={12} hidden={props.parentInstance.state.treeviewMode === true || props.parentInstance.state.reportMode === true
                || props.parentInstance.state.editMode === true || props.parentInstance.state.addMode === true || props.parentInstance.state.moveCopyMode === true
                                            || props.parentInstance.state.customerAdminMode === true || props.parentInstance.state.dashboardMode === true}>
                <SearchbarGH instance={props.parentInstance} />
            </Grid>
        </>
    );
};