import authService from '../api-authorization/AuthorizeService';

export async function request(strRequestAndParams) {
    const token = await authService.getAccessToken();
    const response = await fetch(strRequestAndParams, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    return data;
}

export async function post(strRequestAndParams, data) {
    const token = await authService.getAccessToken();
    const response = await fetch(strRequestAndParams, {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Pragma': 'no-cache'
        },
        body: JSON.stringify(data)
    });
    const result = response.json();
    return result;
}