import * as httpLib from '../library/httpRequestLibrary';

/* 
 * 
 * All functions related to switching modes in the application 
 * 
*/


// deprecated - will be removed
export function enableDashboardMode(instance) {

}

export function enableHomeMode(instance) {
    instance.setState({ dashboardMode: true, inventoryMode: false, treeviewMode: false, reportMode: false, customerAdminMode: false, searchMode: false, moveCopyMode: false});
};

export function enableSearchMode(instance) {
    if (instance.state.searchMode === false)
        instance.setState({ searchMode: true, readOnlyMode: true, editMode: false, addMode: false, moveCopyMode: false, dashboardMode: false, inventoryMode: true, treeviewMode: false, reportMode: false, customerAdminMode: false, moveCopyMode: false });
};

export function enableInventoryMode(instance) {
    instance.setState({ dashboardMode: false, inventoryMode: true, treeviewMode: false, reportMode: false, customerAdminMode: false, searchMode: false, moveCopyMode: false});
};

export async function enableReportMode(instance) {
    instance.setState({ dashboardMode: false, inventoryMode: false, treeviewMode: false, reportMode: true, customerAdminMode: false, searchMode: false, moveCopyMode: false });
    instance.handleReportOptionsControlClick(instance.state.hack_rptTypeRadioBtn, instance.state.inventory.id);
};

export async function enableTreeViewModeAsync(instance, inventoryId) {
    instance.setState({
        treeLoading: true, treeviewMode: true, dashboardMode: false, reportMode: false, inventoryMode: false,
        editMode: false, moveCopyMode: false, customerAdminMode: false, searchMode: false
    });
    const treeData = await httpLib.request("tree?id=" + inventoryId);
    instance.loadRigCheckPctCompleteAsync(inventoryId);
    instance.setState({
        uiTreeData: treeData[0], treeLoading: false, treeviewMode: true});             
};

export async function enableCustomerAdminModeAsync(instance) {
    instance.setState({
        loading: true, dashboardMode: false, customerAdminMode: true, treeviewMode: false, reportMode: false,
        inventoryMode: false, editMode: false, moveCopyMode: false, searchMode: false
    });
    instance.setState({ loading: false });
};

export async function enableAddMode(instance, inventoryJson) {
    await instance.loadCustomerCategories();
    instance.setState({
        editMode: true, addMode: true, moveCopyMode: false, hack_inventoryName: "", hack_inventoryDescription: "", hack_gearCheckInstructions: "",
        hack_itemType: "1", imageUrl: "", parentImageUrl: "", hack_gearCheckComment: "", hack_inventoryQuantity: "1", hack_inventoryMinimumQuantity: "1", hack_displayOrder: "50", hack_inventoryExpirationDate: "",
        hack_isInventory: true, hack_outOfService: false, hack_needsRepair: false, hack_needsResupply: false, hack_categories: []
    });
    inventoryJson.ParentId = inventoryJson.id;
    // HACK: set an invalid id, but not negative one.
    // Negative one represents a particular state 
    // that we do not want to enable in this case.
    inventoryJson.id = -2;
    if (inventoryJson.Level !== undefined)
        inventoryJson.Level = inventoryJson.Level + 1;
};

export async function enableEditMode(instance) {
    await instance.loadCustomerCategories();
    instance.setState({ editMode: true, moveCopyMode: false, searchMode: false, reportMode: false });
};

// This mode is tracked as a string. Possible values: "img", "noImg" or "tbl".
export function toggleGlobalImageMode(instance, value) {
    instance.setState({ globalImageMode: value });
};