import React from 'react';
import MaterialTable from 'material-table';
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import authService from './api-authorization/AuthorizeService';

export default function InventoryTable(props) {
    const tableRowClicked = (event, rowData) => {
        props.instance.getNode(rowData.id, event);
    }

    const [state, setState] = React.useState({
        columns: [
            { title: 'Name', field: 'Name', searchable: true },
            { title: 'NodeId', field: 'Id', hidden: true, searchable: false },
            { title: 'Location', field: 'ParentName', searchable: false, export: true },
            { title: 'Category', field: 'CategoriesString', searchable: true },
            {
                title: 'Status', field: 'GearCheckStatus', searchable: true
            },
            { title: 'Comments', field: 'GearCheckComment', searchable: false },
            { title: 'Quantity', field: 'Quantity', export: true, searchable: false },
            { title: 'Minimum Quantity', field: 'MinimumQuantity', export: true, searchable: false },
            {
                title: 'Type', field: 'ItemType', export: true, searchable: false,
                render: (rowData) => {
                    if (rowData.ItemType === "1")
                        return "unit";
                    else if (rowData.ItemType === "2")
                        return "box";
                    else if (rowData.ItemType === "3")
                        return "pkg";
                }
            },
             {
                title: 'Expiration', field: 'ExpirationDate', export: true, searchable: false,
                render: (rowData) => {
                    let x = new Date(rowData.ExpirationDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
                    let commaIndex = x.indexOf(",");
                    return rowData.ExpirationDate === null || rowData.ExpirationDate.startsWith("0001-") ? " " : x.substring(0, commaIndex);
                }
            },
            { title: 'Notes', field: 'GearCheckComment', emptyValue: '', searchable: false },
            {
                title: 'Date', field: 'GearCheckDateTime',
                render: rowData => new Date(rowData.GearCheckDateTime).toLocaleString('en-US', { timeZone: 'America/New_York' })
            },
            { title: 'Description', field: 'Description', export: false, searchable: false }
         ],
        data: props.rows,
        title: props.title
    });

    return (
        <MaterialTable
            options={{
                pageSize: 25,
                paging: true,
                exportButton: true,
                exportAllData: true,
                padding: "dense",
                rowStyle: {
                    fontSize: 12,
                },
            }}
            icons={{
                Add: AddBox,
                Check: Check,
                Clear: Clear,
                Delete: DeleteOutline,
                DetailPanel: ChevronRight,
                Edit: Edit,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                ResetSearch: Clear,
                Search: Search,
                SortArrow: ArrowUpward,
                ThirdStateCheck: Remove,
            }}
            onRowClick={tableRowClicked}
            title={setTitle( state.title)}
            columns={state.columns}
            data={state.data}
            editable_leavingThisOffForNow={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            postUpdateAndRefresh(newData);
                            resolve();
                            if (oldData) {
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            }
                        }, 600);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
            }}
        />
    );
};

async function postUpdateAndRefresh(newData) {
    const token = await authService.getAccessToken();
    const url = "admin";
    const response = await fetch(url, {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Pragma': 'no-cache'
        },
        body: JSON.stringify(newData)
    });
    const data = response.json();
    return data;
};

function setTitle(title) {
    return <b>{title}</b>;
}