import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import authService from '../api-authorization/AuthorizeService';
import CustomerAdminUsersTable from './CustomerAdminUsersTable';
import CustomerAdminCategoriesTable from './CustomerAdminCategoriesTable';
import CustomerAdminRigCheck from './CustomerAdminRigCheck';

export default class CustomerAdmin extends Component {
    static displayName = CustomerAdmin.name;

    constructor(props) {
        super(props);
        this.state = { loading: false, currentTab: "users", custUsersMode: true, custCategoriesMode: false, custRigCheckMode: false};
        this.setCustomerUserMode = this.setCustomerUserMode.bind(this);
        this.setCustomerCategoriesMode = this.setCustomerCategoriesMode.bind(this);
        this.setCustomerRigCheckMode = this.setCustomerRigCheckMode.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);

        this.loadCustomerAdminAccessData();
    }

    async loadCustomerAdminAccessData() {
       try {
            const token = await authService.getAccessToken();
            const response = await fetch("customeradmin?objType=users", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response === undefined) {
                alert("error. response is null.");
                this.setState({ loading: false });
            }
            else if (response.status === 401) {
                this.setState({ loading: false });
            }
            else {
                const data = await response.json();
                var customerAdminUserAccess = JSON.parse(data[0]);
                this.setState({
                    roles: data[1],
                    customerUserData: customerAdminUserAccess,
                    loading: false

                });
                return customerAdminUserAccess;
            }
        }
        catch (error) {
            this.setState({ loading: false })
            alert("admin error..." + error);
        };
    };

    async loadCustomerCategories() {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch("customeradmin?objType=categories", {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            if (response === undefined) {
                alert("error. response is null.");
                this.setState({ loading: false });
            }
            else if (response.status === 401) {
                this.setState({ loading: false });
            }
            else {
                const data = await response.json();
                var customerAdminCategories = JSON.parse(data);
                this.setState({
                    customerCategories: customerAdminCategories, loading: false
                });
               return customerAdminCategories;
            }
        }
        catch (error) {
            this.setState({ loading: false })
            alert("admin error..." + error);
        };
    };

    setCustomerUserMode() {
        this.setState({ custUsersMode: true, custCategoriesMode: false, custRigCheckMode: false });
        this.loadCustomerAdminAccessData();
    }

    setCustomerCategoriesMode() {
        this.setState({ custUsersMode: false, custCategoriesMode: true, custRigCheckMode: false });
        this.loadCustomerCategories();
    }

    setCustomerRigCheckMode() {
        this.setState({ custRigCheckMode: true,  custUsersMode: false, custCategoriesMode: false });
       // this.loadCustomerRigCheck();
    }   

    handleTabChange = (event, newValue) => {
        this.setState({ currentTab: newValue });
    };

    render() {
        return <Grid container justify="space-evenly">
             <Grid item xs={12}>
                <Paper style={{ flexGrow: 1 }}>
                    <Tabs
                        value={this.state.currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab hidden={true} label="Rig Check" value="rigCheck" disableRipple={true} onClick={this.setCustomerRigCheckMode} />
                        <Tab label="Users" value="users" disableRipple={true} onClick={this.setCustomerUserMode} />
                        <Tab label="Categories" value="categories" disableRipple={true} onClick={this.setCustomerCategoriesMode}/>
                     </Tabs>
                </Paper>
            </Grid>
            {this.renderCustomerRigCheck()}
            {this.renderCustomerUserTable()}
            {this.renderCustomerCategoriesTable()}
 
        </Grid>;
    };

    renderCustomerRigCheck() {
        if (this.state.custRigCheckMode === true) {
            return (
                <Grid item xs={12}>
                    <br />
                    <div color="inherit" align="center" variant="body1">
                       <CustomerAdminRigCheck/>
                    </div>
                </Grid>
            );
        }
    }

    renderCustomerUserTable() {
        if (this.state.custUsersMode === true) {
            return (
                <Grid item xs={12}>
                    <br />
                    <div color="inherit" align="center" variant="body1">
                        {this.state.customerUserData ? <CustomerAdminUsersTable users={this.state.customerUserData} /> : <div>Loading Users...</div>}
                    </div>
                </Grid>
            );
        }
    }

    renderCustomerCategoriesTable() {
          if (this.state.custCategoriesMode === true) {
            return (
                <Grid item xs={12}>
                    <br />
                    <div color="inherit" align="center" variant="body1">
                        {this.state.customerCategories ? <CustomerAdminCategoriesTable categories={this.state.customerCategories} /> : <div>Loading Categories...</div>}
                    </div>
                </Grid>
            );
        }
    }
};