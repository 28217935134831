import React from 'react';
import 'react-image-lightbox/style.css';
import { Breadcrumbs, Toolbar, Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';

export default function BreadcrumbGH(props) {
    const fireGetNodeEvent = (item, e) => {
        props.getNode(item.id, e);
    };

    if (props.loading || props.inventoryLoading) {
        return (null)
    }
    else {
        var shortBreadcrumb = props.breadcrumbJson;
        if (shortBreadcrumb.length > 1 && (props.trimBreadcrumbArray === undefined || props.trimeBreadcrumbArray === false)) {
            shortBreadcrumb.pop();
        };
        return (
            <div >
                <Grid container justify="flex-start" align-items="flex-start">
                    <Grid item xs={12} md={12}>
                        <Toolbar>
                            <Breadcrumbs maxItems={6} separator="|" aria-label="breadcrumb">
                                {shortBreadcrumb.map(item =>
                                    <div><Link variant="subtitle1" tag="a" href="#" to="#" key={item.id} onClick={e => fireGetNodeEvent(item, e)} >{item.Name}</Link></div>
                                )}
                            </Breadcrumbs>
                        </Toolbar>
                    </Grid>

                </Grid>
            </div >
        );
    };
};