import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { grey, green } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Linkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import TitleAndStatusIcons from './TitleAndStatusIcons';
import ModalGearCheckForm from './ModalGearCheckForm';
import MainItemAudit from './audit/MainItemAudit';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        paddingTop: '0%',
        paddingBottom: '0%',
        paddingLeft: 1,
        paddingRight: 1,
    },
    media: {

        paddingTop: '95.25%', // 16:9
        maxHeight: 50,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: grey[500],
        hidden: true,
    },
    cardHeader: {
        paddingTop: '0%',
        paddingBottom: '0%',
    },
    childCard: {
        maxWidth: 250,
        padding: 0,
        justifyContent: 'center',
       
    }
}));

export default function ChildInventoryCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };

    const handleImageClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick(e);
        return false;
    };

    const getExpandIcon = (strDescription) => {
        if (strDescription === undefined || strDescription.length > 0) {
            return (<ExpandMoreIcon style={{ color: green[800] }} fontSize={"small"} />);
        }
        else {
            return "";
        }
    };

    return (
        <Card className={classes.childCard} raised={true}>
            <CardHeader className={classes.cardHeader}
                action={
                         <MainItemAudit inventoryJson={props.inventoryNode}></MainItemAudit>
                }
                style={{ maxHeight: '65px' }}
                title={<TitleAndStatusIcons inventoryName={ props.inventoryName} status={props.gearCheckStatus} variantType="body2" doBoldFont={true}
                    isInventory={props.isInventory} needsRepair={props.needsRepair} needsResupply={props.needsResupply} outOfService={props.outOfService} shortenTitle={false}
                    quantity={props.inventoryNode.Quantity} minimumQuantity={props.inventoryNode.MinimumQuantity} itemType={props.itemType}
                    expirationDate={props.inventoryNode.ExpirationDate}/>}
                onClick={handleImageClick}
            />
            <CardMedia
                className={classes.media}
                image={props.imageUrl}
                title={ props.inventoryName}
                onClick={handleImageClick}
                hidden={props.globalImageMode !== "img"}
            />
            <CardActions disableSpacing className={classes.root} style={{ maxHeight: "35px" }}>
                <ModalGearCheckForm onClick={handleImageClick} roles={props.roles} inventoryId={props.inventoryId} needsRepair={props.needsRepair} needsResupply={props.needsResupply}
                    outOfService={props.outOfService} gearCheckComment={props.gearCheckComment}
                    inventoryNode={props.inventoryNode} gearCheckStatus={props.gearCheckStatus} itemType={props.itemType} gearCheckInstructions={props.gearCheckInstructions}
                 />
                <IconButton 
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    { getExpandIcon(props.description) }
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <SecureLink href={decoratedHref} key={key}>{decoratedText}</SecureLink>
                    )}>
                        <Typography variant="body2" color="textSecondary" component="p" >
                            {props.description}
                        </Typography>
                    </Linkify>
                </CardContent>
            </Collapse>
        </Card>
    );
};