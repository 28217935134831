import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
    Button, ButtonGroup, Card, CardContent, Grid} from '@material-ui/core';
import '../Reports.css';
import MainItemCard from '../MainItemCard';
import * as eventLib from '../library/eventLibrary';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import CustomerAdmin from '../customerAdmin/CustomerAdmin';
import * as mode from '../library/mode';

export default function HomeReadOnly(props) {
    return (
        <div width="100%" style={{ padding: "0px" }}>
            {/* the scrolling photo box */}
            {props.instance.state.modal && (
                <Lightbox
                    mainSrc={props.instance.images[props.instance.state.photoIndex]}
                    nextSrc={props.instance.images[(props.instance.state.photoIndex + 1) % props.instance.images.length]}
                    prevSrc={props.instance.images[(props.instance.state.photoIndex + props.instance.images.length - 1) % props.instance.images.length]}
                    onCloseRequest={() => props.instance.setState({ modal: false })}
                    onMovePrevRequest={() =>
                        props.instance.setState({
                            photoIndex: (props.instance.state.photoIndex + props.instance.images.length - 1) % props.instance.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        props.instance.setState({
                            photoIndex: (props.instance.state.photoIndex + 1) % props.instance.images.length,
                        })
                    }
                />
            )}
            <Grid container direction="row" align-items="center">
                <Grid item xs={12} hidden={!props.instance.state.customerAdminMode}>
                    {props.instance.state.customerAdminMode
                        ? <CustomerAdmin instance={props.instance} roles={props.roles} />
                        : <></>
                    }
                </Grid>
                <Grid item xs={false} md={2}></Grid>
                <Grid item xs={12} md={8} hidden={!props.instance.state.inventoryMode}>
                    <Card align="center" hidden={props.instance.state.reportMode || props.instance.state.treeviewMode || props.instance.state.searchMode} style={{ padding: "4px", paddingBottom: "0px" }} raised={true}>
                        <CardContent style={{ padding: "0px" }}>
                            <Grid container spacing={1} justify="space-evenly" style={{ padding: "2px" }} alignItems="flex-start">
                                <Grid item xs={12} md={props.instance.state.globalImageMode === "img" ? 8 : 12} style={{ padding: "0px", align: "center" }}>
                                    {props.instance.mainCard_nodeInformationDisplayFormatter(props.inventoryJson, props.breadcrumbJson)}
                                </Grid>
                                <Grid hidden={props.instance.state.globalImageMode === "noImg"} item xs={12} md={4} style={{ padding: "1px", align: "right" }}>
                                    <MainItemCard parentName={""} inventoryName={props.instance.state.hack_inventoryName} level={props.instance.state.inventory.Level}
                                        needsRepair={props.instance.state.hack_needsRepair} needsResupply={props.instance.state.hack_needsResupply}
                                        isInventory={props.instance.state.hack_isInventory} outOfService={props.instance.state.hack_outOfService}
                                        description={props.instance.state.hack_inventoryDescription} instructions={props.instance.state.hack_gearCheckInstructions}
                                        imageUrl={props.instance.state.imageUrl} parentImageUrl={props.instance.state.parentImageUrl}
                                        globalImageMode={props.instance.state.globalImageMode} onClick={props.instance.toggleModalImage} />
                                </Grid>

                            </Grid>
                        </CardContent>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={1} md={4}></Grid>
                            <Grid item xs={10} md={4} >
                                <ButtonGroup color="primary" fullWidth={true} hidden={false}>
                                    <Button size="small" disabled={!props.roles.includes("Edit")} onClick={() => mode.enableAddMode(props.instance, props.inventoryJson)}>
                                        <AddOutlinedIcon size="small" />
                                    </Button>
                                    <Button size="small" disabled={!props.roles.includes("Edit")} onClick={e => mode.enableEditMode(props.instance)}>
                                        <EditOutlinedIcon size="small" />
                                    </Button>
                                    <Button size="small" disabled={!props.roles.includes("MoveCopy")} onClick={e => eventLib.enableMoveCopyMode(props.instance)}>
                                        <FileCopyOutlinedIcon size="small" />
                                    </Button>
                                    <Button size="small" disabled={!props.roles.includes("Delete")} onClick={e => props.instance.handleDeleteItem(props.inventoryJson)}>
                                        <DeleteForeverOutlinedIcon size="small" />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={1} md={4}></Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={false} md={2}> </Grid>
            </Grid>
        </div>
    )
};