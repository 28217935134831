import React from 'react';
import MaterialTable from 'material-table';
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import * as httpLib from '../library/httpRequestLibrary';

export default function CustomerAdminCategoriesTable(props) {
    const [state, setState] = React.useState({
        data: props.categories,
    });

    return (
        <MaterialTable
            options={{
                addRowPosition: "first",
                pageSize: 100,
                paging: false,
                exportButton: true
            }}
            columns={
                [
                    { title: 'Name', field: 'Name', searchable: true, initialEditValue: "Name" },
                    { title: 'Id', field: 'Id', hidden: true },
                ]
            }
            icons={{
                Add: AddBox,
                Check: Check,
                Clear: Clear,
                Delete: DeleteOutline,
                DetailPanel: ChevronRight,
                Edit: Edit,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                ResetSearch: Clear,
                Search: Search,
                SortArrow: ArrowUpward,
                ThirdStateCheck: Remove,
            }}

            title="Categories"
            data={state.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            postNewCategoryAndRefresh(newData);
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            //validateFieldsForSubmission(newData);
                            postCategoryUpdateAndRefresh(newData);
                            resolve();
                            if (oldData) {
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            }
                        }, 600);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            postCategoryDeleteAndRefresh(oldData);
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
            }}
        />
    );
};

async function postCategoryUpdateAndRefresh(updateData) {
    const data = httpLib.post("customeradmin?action=update&objType=category", updateData);
    return data;
};

async function postCategoryDeleteAndRefresh(oldData) {
    const data = httpLib.post("customeradmin?action=delete&objType=category", oldData);
    return data;
};

async function postNewCategoryAndRefresh(newData) {
    const data = httpLib.post("customeradmin?action=add&objType=category", newData);
    return data;
};

//function validateFieldsForSubmission(data) {
//    var categoryName = data.CategoryName;
//    if (categoryName === undefined || categoryName.length < 3) {
//        alert("You must enter a valid category name.");
//        return false;
//    }
//    else {
//        return true;
//    }
//};