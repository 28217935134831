import React from 'react';
import authService from './api-authorization/AuthorizeService';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/Build';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { green, red, blue } from '@material-ui/core/colors';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';


export default function ModalGearCheckForm(props) {
    const [open, setOpen] = React.useState(false);
    const [gearCheckStatus, setGearCheckStatus] = React.useState(props.gearCheckStatus);
    const [gearCheckComment, setGearCheckComment] = React.useState(props.gearCheckComment);
    const inventoryNode = props.inventoryNode;
    const [statusCheckValid, setStatusCheckValid] = React.useState(inventoryNode.GearCheckValid);
    const [inventoryQuantity, setInventoryQuantity] = React.useState(inventoryNode.Quantity);
    const [inventoryMinimumQuantity, setInventoryMinimumQuantity] = React.useState(inventoryNode.MinimumQuantity);
    const [inventoryExpirationDate, setInventoryExpirationDate] = React.useState(inventoryNode.ExpirationDate);
    const [thumbsUpClicked, setThumbsUpClicked] = React.useState(false);
    const roles = props.roles;

    const handleDateChange = (date) => {
        setInventoryExpirationDate(date);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    // This is for counter state variable
    React.useEffect(() => {
        if (thumbsUpClicked === true) {
            postNewGearStatus();
        }
    });

    const handleStatusOk = () => {
        setGearCheckStatus("ok");
        setThumbsUpClicked(true);
        setStatusCheckValid(true);
        inventoryNode.OutOfService = false;
        inventoryNode.NeedsRepair = false;
        inventoryNode.NeedsResupply = false;
        setGearCheckComment("");
    };

    const handleUpdateStatus = (e) => {
        postNewGearStatus();
        setStatusCheckValid(true);
        setOpen(false);
    };

    const handleRadioChange = (event) => {
        setGearCheckStatus(event.target.value);
    };

    const getStatusCheckValid = (bOk) => {
        if (bOk && inventoryNode.OutOfService)
            return { color: red[900] };
        else if (bOk && (inventoryNode.NeedsRepair || inventoryNode.NeedsResupply || parseInt(inventoryNode.Quantity) < parseInt(inventoryNode.MinimumQuantity)))
            return { color: red[900] };
        else return bOk ? { color: green[600] } : { color: blue[200] };
    };

    const getThumbsUpButton = (node) => {
        if (node.OutOfService || node.NeedsRepair || node.NeedsResupply || parseInt(inventoryNode.Quantity) < parseInt(inventoryNode.MinimumQuantity)) {
            return <></>
        }
        else {
            return <IconButton aria-label="issues" onClick={handleStatusOk}>
                <ThumbUpIcon style={getStatusCheckValid(statusCheckValid)} />
            </IconButton>
        }
    };

    const handleMinimumQuantityChange = (quant) => {
        const re = /^[0-9\b]+$/; // integers only
        if (quant === '' || re.test(quant)) {
            setInventoryMinimumQuantity(quant);
        }
        else {
            alert("Enter zero or a positive number for minimum quantity.");
        }
    };

    const handleQuantityChange = (quant) => {
        const re = /^[0-9\b]+$/; // integers only
        if (quant === '' || re.test(quant)) {
            setInventoryQuantity(quant);
        }
        else {
            alert("Enter zero or a positive number for quantity.");
        }
    };

    const getQuantityForInventory = () => {
        if (inventoryNode.IsInventory) {
            return <>
                {roles.includes("SetMinimumQuantity") ?
                        <TextField type="textarea" label="Minimum Quantity" variant="filled" style={{ margin: 6 }} value={inventoryMinimumQuantity} onChange={e => handleMinimumQuantityChange(e.target.value)} id="nodeMinQuantity" />
                    :
                        <Paper elevation={0}><i>Minimum Quantity: {inventoryNode.MinimumQuantity}</i></Paper>}
                <TextField type="textarea" label="Quantity" variant="filled" style={{ margin: 6 }} value={inventoryQuantity} onChange={e => handleQuantityChange(e.target.value)} id="nodeQuantity" />
            </>
        }
    };

    const formatExpirationDate = (strDate) => {
        return strDate;
    };

    const postNewGearStatus = async () => {
        try {
            inventoryNode.GearCheckStatus = gearCheckStatus;
            if (gearCheckStatus === 'resupply' || inventoryQuantity < inventoryMinimumQuantity) {
                inventoryNode.OutOfService = false;
                inventoryNode.NeedsRepair = false;
                inventoryNode.NeedsResupply = true;
            }
            else if (gearCheckStatus === 'oos') {
                inventoryNode.OutOfService = true;
                inventoryNode.NeedsRepair = false;
                inventoryNode.NeedsResupply = false;
            }
            else if (gearCheckStatus === 'repair') {
                inventoryNode.NeedsRepair = true;
                inventoryNode.OutOfService = false;
                inventoryNode.NeedsResupply = false;
            }
            else if (gearCheckStatus === 'ok') {
                inventoryNode.OutOfService = false;
                inventoryNode.NeedsRepair = false;
                inventoryNode.NeedsResupply = false;
            }

            if (inventoryExpirationDate === null || inventoryExpirationDate.length === 0) {
                inventoryNode.ExpirationDate = "1001-02-11";
            }
            else {
                inventoryNode.ExpirationDate = inventoryExpirationDate;
            }

            inventoryNode.Quantity = inventoryQuantity;
            inventoryNode.MinimumQuantity = inventoryMinimumQuantity;
            inventoryNode.GearCheckComment = gearCheckComment;
            inventoryNode.Action = 5;
            const token = await authService.getAccessToken();
            const url = "inventoryhome";
            const response = await fetch(url, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Pragma': 'no-cache'
                },

                body: JSON.stringify(inventoryNode)
            });
            const data = response.json();
            return data;
        }
        catch (error) {
            alert("error...");
            alert(JSON.stringify(error));
        }
    };

    return (
        <>
            <IconButton aria-label="issues" onClick={handleClickOpen}>
                <BuildIcon style={getStatusCheckValid(statusCheckValid)} />
            </IconButton>
            {getThumbsUpButton(inventoryNode)}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{inventoryNode.Name}&nbsp;Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <RadioGroup aria-label="status" name="status1" value={gearCheckStatus} row onChange={handleRadioChange}>
                            <FormControlLabel value="ok" control={<Radio />} label="Ok" />
                            <FormControlLabel value="repair" control={<Radio />} label="Repair" />
                            <FormControlLabel value="resupply" control={<Radio />} label="Resupply" />
                            <FormControlLabel value="oos" control={<Radio />} label="Out of Service" />
                        </RadioGroup>
                    </DialogContentText>
                    {getQuantityForInventory()}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            emptyLabel=""
                            label="Expiration"
                            format="MM/dd/yyyy"
                            value={formatExpirationDate(inventoryExpirationDate)}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Info"
                        type="text"
                        fullWidth
                        value={gearCheckComment}
                        onChange={e => setGearCheckComment(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateStatus} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};