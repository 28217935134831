import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Paper, Grid } from '@material-ui/core';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import RowingOutlinedIcon from '@material-ui/icons/RowingOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import * as mode from '../library/mode';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();

    const enableSearchMode = () =>{
        const objSearchCriteria = { Name: "", Id: -1, Text: "", Categories: "", Status: "" };
        props.parentInstance.setState({ searchCriteria: objSearchCriteria });
        mode.enableSearchMode(props.parentInstance);
    };

    const tasksUnderConstruction = () => {
        alert("Work in Progress! This area is under construction");
    }

    return (
        <div>
            <Grid container spacing={3} direction="row" justify="space-between" align-items="flex-start">
                <Grid item xs={12} sm={3}>
                    <img src="https://cs2af83b2550815x4824x9e5.blob.core.windows.net/imagecontainer/dashboard.png" width="95%" />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Inventory<br />
                        <Button variant="contained"  onClick={e => { e.preventDefault(); e.stopPropagation(); mode.enableInventoryMode(props.parentInstance); }}> <AppsOutlinedIcon size="large"/></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        View, update and manage inventory
                     </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Rig Check<br />
                        <Button variant="contained" onClick={e => { e.preventDefault(); e.stopPropagation(); mode.enableTreeViewModeAsync(props.parentInstance, props.inventoryJson.id); }}><DoneOutlineOutlinedIcon size="large" /></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        Perform rig or status checks
                     </Paper>    
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Reports<br />
                        <Button variant="contained"  onClick={e => { e.preventDefault(); e.stopPropagation(); mode.enableReportMode(props.parentInstance) }}> <AssignmentOutlinedIcon size="large" /></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        View, download or email reports
                     </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Search<br />
                        <Button variant="contained"  onClick={e => { e.preventDefault(); e.stopPropagation(); enableSearchMode() }}> <AssignmentOutlinedIcon size="large" /></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        Search inventory by name, status or category
                     </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Tasks<br />
                        <Button variant="contained" onClick={e => { e.preventDefault(); e.stopPropagation(); tasksUnderConstruction() }}> <RowingOutlinedIcon size="large"/></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        Create and schedule maintenance and training tasks
                     </Paper>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Paper className={classes.paper}>Administration<br />
                        <Button variant="contained" onClick={e => mode.enableCustomerAdminModeAsync(props.parentInstance)}> <SettingsOutlinedIcon size="large" /></Button>
                    </Paper>
                    <Paper className={classes.paper}>
                        Configure user accounts, categories and more
                     </Paper>
                </Grid>
            </Grid>
        </div>
    );
};